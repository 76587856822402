import { gql } from 'apollo-boost';
import { UserFragment } from './user'
import { MediaImageFragment } from './images'
import { MediaEventDocumentFragment, MediaDocumentFragment, MediaPrivateDocumentFragment } from './documents'
import { settingConfiguration } from '../storage';
import { BASE_URL } from '../constants';
import axios from '../helpers/axios-helpers';

export const SponsorItemFragment = gql`
fragment SponsorItemFragment on NodeSponsor {
  id:nid
  title
  logo: fieldLogo {
    entity {
      thumbnail {
        title
        alt
        original: entity{
          url
        }
        medium: derivative(style: MEDIUM) {
          url
          width
          height
        }
        large: derivative(style: LARGE) {
          url
          width
          height
        }
      }
    }
  }
  link:fieldLink {
    uri
  }
  content: body {
    processed
  }
}
`;

export const SponsorFragment = gql`
fragment SponsorFragment on ParagraphSponsor {
  title: fieldTitle
  sponsor: fieldSponsor {
    entity {
      ...SponsorItemFragment
    }
  }
}

${SponsorItemFragment}
`;



export const EventProgramFragment = gql`
fragment EventProgramFragment on ParagraphEventProgram {
  title: fieldTitle
  date: fieldDate {
    value
    date
  }
  from: fieldFromHour
  to: fieldToHour
  description: fieldDescriptionWysiwyg {
    processed
  }
  speakers: fieldSpeakers {
    entity {
      ...UserFragment
    }
  }
  documents: fieldDocuments {
    entity {
      ...MediaImageFragment
      ...MediaDocumentFragment
      ...MediaPrivateDocumentFragment
    }
  }
}

${MediaDocumentFragment}
${MediaImageFragment}
${MediaPrivateDocumentFragment}
${UserFragment}
`;

export const AttachmentFragment = gql`
fragment AttachmentFragment on ParagraphAttachment {
  title: fieldTitle
  attachment: fieldAttachment {
    entity {
      ... on ParagraphAttachmentFile {
        file: fieldFile{
          entity {
            id:entityId
            ... on MediaDocument {
              document: fieldMediaDocument {
                entity {
                  ... on File {
                    url
                    filename
                    filemime
                  }
                }
              }
            }
          }
        }
      }
      ... on ParagraphAttachmentLink {
        link: fieldLink {
          uri
        }
      }
    }
  }
}
`;

export const EventFragment = gql`
fragment EventFragment on NodeEvent {
  id: nid
  title
  startDate
  endDate
  startDateTz
  endDateTz
  path {
    alias
  }
  venue: fieldVenue
  address: fieldAddress
  accessType: fieldAccessType {
    entity {
      entityLabel
    }
  }
  city:fieldCity
  created
  backgroundImage: fieldBackgroundImage{
    entity{
      ...MediaImageFragment
    }
  }
  category:fieldCategory {
    entity {
      label:entityLabel
    }
  }
  description:fieldDescription
  dates:fieldDatesSmart {
    start:value
    end:endValue
  }
  content: body {
    value
    processed
    summary
    summaryProcessed
  }
  brochure: fieldBrochure {
    entity {
      ...AttachmentFragment
    }
  }
  sponsors: fieldSponsors {
    entity {
      ...SponsorFragment
    }
  }
  attendees: queryFieldAttendees (limit: 1000000) {
    count
    users: entities {
      id: entityId
    }
  }
  pictures: fieldPictures {
    entity {
      ...MediaImageFragment
    }
  }
  appSponsor: fieldAppSponsor {
    entity {
      ...SponsorItemFragment
    }
  }
  eventPrograms: fieldEventProgram {
    entity {
      ...EventProgramFragment
    }
  }
  documents: fieldDocuments {
    entity {
        ...MediaEventDocumentFragment
    }
  }
  surveys: fieldSurveys {
    uri
    title
  }
  practicalInformation: fieldPracticalInformation{
    processed
  }
  isPartner: fieldPartner
  isCommitteeMeeting: fieldCommitteeMeeting
  registration: fieldRegister {
    targetId
  }
}

${SponsorFragment}

${AttachmentFragment}

${MediaImageFragment}

${MediaEventDocumentFragment}

${MediaDocumentFragment}

${EventProgramFragment}

${SponsorItemFragment}
`;

export const GetEventListQuery = gql`
query events($page: Int, $pageSize: Int, $region: String, $category: String) {
  upcomingEvents(page: $page, pageSize: $pageSize, filter: {field_region_target_id: $region, field_category_target_id: $category}) {
    count
    results {
      ...EventFragment
    }
  }
}

${EventFragment}
`;

export const GetPastEventListQuery = gql`
query events($page: Int, $pageSize: Int, $region: String, $category: String) {
  pastEvents(page: $page, pageSize: $pageSize, filter: {field_region_target_id: $region, field_category_target_id: $category}) {
    count
    results {
      ...EventFragment
    }
  }
}

${EventFragment}
`;

export const GetEventDetailQuery = gql`
  query($id: String!) {
    result: nodeById(id: $id) {
      ...EventFragment
    }
  }

  ${EventFragment}
`;

export const GetMyEventListQuery = gql`
query events($page: Int, $pageSize: Int) {
  myEvents(page: $page, pageSize: $pageSize) {
    count
    results {
      ...EventFragment
    }
  }
}

${EventFragment}
`;

export const GetEventAttendeesQuery = gql`
query ($id: String!, $filters: [EntityQueryFilterInput], $limit:Int = 50, $offset:Int = 0) {
  result: nodeById(id: $id) {
    ... on NodeEvent {
      id: nid
      attendees:queryFieldAttendees(
        sort:{
          field:"field_name.family",
          direction:ASC
        }
        ,filter: {
        conjunction: AND, 
        groups: $filters
      }, offset: $offset, limit: $limit) {
        count
        entities {
          ...UserFragment
        }
      }
    }
  }
}

${UserFragment}
`;

export function GenerateAttendeesFilters(name, country) {
    const groups = [];
    if (name && name !== "") {
        const nameGroup = {
            "conjunction": "OR",
            "conditions": []
        }
        const nameCondition = [];
        nameCondition.push({"field": "name", "operator": "LIKE", "value": `%${name}%`})
        nameCondition.push({"field": "field_name.given", "operator": "LIKE", "value": `%${name}%`})
        nameCondition.push({"field": "field_name.middle", "operator": "LIKE", "value": `%${name}%`})
        nameCondition.push({"field": "field_name.family", "operator": "LIKE", "value": `%${name}%`})
        nameCondition.push({"field": "field_job_title", "operator": "LIKE", "value": `%${name}%`})
        nameCondition.push({"field": "field_company_name", "operator": "LIKE", "value": `%${name}%`})
        nameGroup["conditions"] = nameCondition;
        groups.push(nameGroup);
    }

    if (country && country !== "") {
        const countryGroup = {
            "conjunction": "OR",
            "conditions": []
        }
        const countryCondition = [];
        countryCondition.push({"field": "field_company_country.target_id", "value": `${country}`})
        countryCondition.push({"field": "field_company_country_calculated.target_id", "value": `${country}`})
        countryGroup["conditions"] = countryCondition;
        groups.push(countryGroup);
    }

    return groups;
}

export const GetMyEventAttendeesQuery = gql`
query (
  $eventId: String!, 
  $name: String = "", 
  $countryId: String = "", 
  $sortDirection: ViewSortDirection = ASC, 
  $sortBy: EventAttendeesGraphql1ViewSortBy = FIELD_NAME_FAMILY, 
  $page: Int = 0, 
  $pageSize: Int = 20
) {
  attendees: eventAttendees(
    filter: {nid: {value: $eventId}, 
    field_company_country_target_id: $countryId, 
    field_company_country_calculated_target_id: $countryId, 
    field_name: $name, 
    field_name_given: $name, 
    field_name_family: $name, 
    field_name_middle: $name, 
    field_company_name_value: $name, 
    field_company_name_calculated_value: $name,
    company_country_name: $name,
    company_calculated_country_name: $name,
    field_job_title_value: $name}, 
    sortBy: $sortBy, 
    sortDirection: $sortDirection, 
    page: $page, 
    pageSize: $pageSize
  ) {
    count
    results {
      ...UserFragment
    }
  }
}

${UserFragment}
`;

export async function SubmitEventQuestion(event_id, question = '') {
    const apiUrl = settingConfiguration.get(BASE_URL) ? settingConfiguration.get(BASE_URL) + 'api/events/ask' : '';
    return axios.post(apiUrl, JSON.stringify({
        "eventId": event_id,
        "question": question
    }));
}