import React from 'react';

import { Trans } from "react-i18next";
import { Button } from '../Form/Button';
import { IconClose } from '../Icons/Icons';
export const Modal = ({ title, children, translation, show, onOk, onCancel, extraButtons, onClose }) => {
    if (!translation)
        translation = {}
    const className = show === true ? "mdl show" : "mdl";

    return (
        <div className={className}>
            <div className="mdl-content">
                <div className="mdl-header">
                    {title ?? <Trans i18nKey="title" >Title</Trans>}
                    <span className="mdl-close" onClick={onClose}>
                        <IconClose />
                    </span>
                </div>
                <div className="mdl-body">
                    {children}
                </div>
                <div className="mdl-footer">
                    {onCancel && <Button className="m-10" size="small" onClick={onCancel}>
                        <span>{translation.cancel ?? <Trans i18nKey="cancel" >Cancel</Trans>}</span>
                    </Button>
                    }
                    {onOk && <Button className="m-10" size="small" variant="violet" onClick={onOk}>
                        <span>{translation.ok ?? <Trans i18nKey="ok" >Ok</Trans>}</span>
                    </Button>
                    }
                    <div className="mdl-extra">
                        {extraButtons && extraButtons.length > 0 && extraButtons.map((n, index) => {
                            return <React.Fragment key={index}>
                                {n}
                            </React.Fragment>;
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};
