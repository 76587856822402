import React, { useEffect, useState } from 'react';
import loading from '../../assets/images/loading.svg';
import { Trans } from "react-i18next";
import pubSub from '@fci/business-layer/helpers/pub-sub';
import { NETWORK_TIMEOUT, NETWORK_ERROR } from '@fci/business-layer/constants';
import { Error, ErrorBoundary } from './Error';
import { TimeoutError } from './TimeoutError';
import networkHelpers from '@fci/business-layer/helpers/network-helpers';
import { settingConfiguration } from '@fci/business-layer/storage';

export const Loader = props => {
    const [error, setError] = useState('');
    const [info, setInfo] = useState('');
    const onTimeout = (error) => {
        setError(NETWORK_TIMEOUT)
        setInfo(error);
    }
    const onError = (error) => {
        setError(NETWORK_ERROR)
        setInfo(error);
    }
    useEffect(() => {
        let timeout = null;
            console.log(`Timeout :${settingConfiguration.get(NETWORK_TIMEOUT)}`)
        if (settingConfiguration.get(NETWORK_TIMEOUT)) {
            timeout = setTimeout(() => {
                if (error === '')
                    setError(NETWORK_TIMEOUT);
            }, settingConfiguration.get(NETWORK_TIMEOUT))
        }

        return () => clearTimeout(timeout);
    }, [settingConfiguration.get(NETWORK_TIMEOUT)])
    useEffect(() => {
        const unsub = pubSub.subscribe(NETWORK_TIMEOUT, onTimeout)
        return () => {
            unsub.remove();
        }
    }, [])
    useEffect(() => {
        const unsub = pubSub.subscribe(NETWORK_ERROR, onError)
        return () => {
            unsub.remove();
        }
    }, [])
    const renderError = () => {
        if (error === NETWORK_ERROR)
            return <Error online={networkHelpers.isOnline()} />
        return <></>;
    }
    const renderTimeOut = () => {
        if (error === NETWORK_TIMEOUT)
            return <TimeoutError data={info} />
        return <></>;
    }
    const renderLoader = () => {
        if (!error)
            return <div className="flex items-center justify-center text-xxs">
                <img style={{ width: 64 }} src={loading} alt={<Trans i18nKey="loading">Loading...</Trans>} />
            </div>
        return <></>;
    }
    return (
        <ErrorBoundary>
            {renderError()}
            {renderTimeOut()}
            {renderLoader()}
        </ErrorBoundary>
    );
};
