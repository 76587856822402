import React, { useState } from 'react';
import moment from "moment";
import { NavLink } from "react-router-dom";
import { Trans } from 'react-i18next';

export const EducationCard = ({ entity }) => {
    const [backgroundImage, setBackgroundImage] = useState('/assets/images/no-pict.png');

    useState(() => {
        if (entity?.images && entity.images.length > 0)
            if (entity.images[0]?.entity?.thumbnail?.medium?.url)
                setBackgroundImage(entity.images[0].entity.thumbnail.medium.url);
    }, [entity]);

    return (
        <article className="flex pt-20 pb-20 b-b ">
            <NavLink to={`/education/${entity.id}`} className="flex flex-1 items-start" exact={true}>

                <div style={{ width: 98 }}>
                    <img className="object-cover object-contain"
                        src={backgroundImage}
                        alt={entity.title} />
                </div>

                <div className="flex-1 ms-20">
                    <div className="flex justify-between">
                        <h2 className="font-semibold text-xs text-green mb-10">{entity?.category?.entity?.name?.toUpperCase()}</h2>
                        {entity.memberOnly === true ? <h2 className="font-bold text-xs text-violet"><Trans i18nKey="membersOnly">Members Only</Trans></h2> : ""}
                    </div>

                    <h2 className="text-base text-blue font-semibold mb-10">{entity.title}</h2>
                </div>
            </NavLink>
        </article>
    );
};
