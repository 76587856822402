import React, { useEffect } from 'react';
import GoogleAnalytic from 'react-ga';
import { settingConfiguration } from '@fci/business-layer/storage';
import { GA_ACCOUNT } from '@fci/business-layer/constants';

export const withTracker = (WrappedComponent, options = {}) => {
    const trackPage = page => {
        SentPageView(page, options)
    };

    return props => {
        useEffect(() => trackPage(props.location.pathname), [
            props.location.pathname
        ]);
        return <WrappedComponent {...props} />;
    };
};
export const SentPageView = (page, options = {}) => {
    GoogleAnalytic.initialize(settingConfiguration.get(GA_ACCOUNT), {
        gaAddress: "/scripts/analytics.js"
    });
    GoogleAnalytic.set({
        page,
        ...options,
    });
    GoogleAnalytic.pageview(page);
}
export const SentEvent = (data, options = {}) => {
    GoogleAnalytic.initialize(settingConfiguration.get(GA_ACCOUNT), {
        gaAddress: "/scripts/analytics.js"
    });
    GoogleAnalytic.event(data)
}
export default withTracker;


