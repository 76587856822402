// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/sw.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          window.addLog(
            'This web app is being served cache-first by a service ' +
            'worker. To learn more, visit https://bit.ly/CRA-PWA'
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

function checkVersionApp() {
  let xmlHttp = new XMLHttpRequest();
  xmlHttp.open("GET", "/scripts/version.json?" + new Date().getTime());
  xmlHttp.onreadystatechange = function () {
    if (xmlHttp.readyState !== 4) return;
    if (xmlHttp.status !== 200 && xmlHttp.status !== 304) {
      window.addLog('HTTP error ' + xmlHttp.status, null);
    } else {
      if (window.localStorage) {
        if (!window.localStorage.getItem("version")) {
          window.localStorage.setItem("version", JSON.parse(xmlHttp.responseText));
        } else {
          if (window.localStorage.getItem("version") !== JSON.parse(xmlHttp.responseText)) {
            let snackbar = document.getElementById('snackbar');
            snackbar.className = 'show';
            const version = document.getElementById('version')
            version.innerText = JSON.parse(xmlHttp.responseText);
          }
        }
      }
    }
  };

  xmlHttp.send();
}
window.addEventListener('click', function () {
  window.addLog(`Check version`)
  checkVersionApp();
});
window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
    window.addLog("Prompt Install")
    window.installPrompter = e
});
function registerValidSW(swUrl, config) {
  window.addLog(`Call Register Valid SW`)
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      window.addLog(`SW supported`)
      swRegistration = registration;
      window.waitingServiceWorker = registration;
      if (!swRegistration) {
        window.addLog('Not Support Service Worker')
        return;
      }
      swRegistration.pushManager.getSubscription()
        .then(function (subscription, b, c, d, e, f, g) {
          window.addLog(`subscription ${JSON.stringify(subscription)}`)
          isSubscribed = !(subscription === null);
          if (!subscription)
            window.addLog('Not Support subscription in push manager')
          else
            window.addLog(subscription)
          if (isSubscribed) {
            if (config.onSubscription)
              config.onSubscription(subscription, swRegistration)
            window.addLog('User is subscribed');
          } else {
            swRegistration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlB64ToUint8Array(applicationKey)
            })
              .then(function (subscription) {
                window.addLog(subscription);
                if (config.onSubscription)
                  config.onSubscription(subscription, swRegistration)


                isSubscribed = true;
              })
              .catch(function (err) {
                if (err.toString().indexOf("permission denied") > 0) {
                  if (window.Configuration.SHOW_BLOCKED_MESSAGE !== "false")
                    window.toastr.warning('Be patient you blocked push notification, so you will not receive push notification from FCI app');
                } else {
                  setTimeout(() => {
                    registerValidSW(swUrl, config)
                  }, 2000);
                }

                window.addLog(`Failed to subscribe user: ${err}`);
              })
          }
        })
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          window.addLog(`state : ${installingWorker.state}`)
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              window.addLog(
                'New content is available and will be used when all ' +
                'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
              );

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              window.addLog('Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch(error => {
      window.addLog('Error during service worker registration:', error);
    });
}





function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' }
  })
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      window.addLog(
        'No internet connection found. App is running in offline mode.'
      );
    });
}
let isSubscribed = false;
let swRegistration = null;
let applicationKey = "BLrI-PeAaBqSlaXekw28wAmkiNEwsQst6Fm9moKDiNQ32uN3JJsPnJdgwHbWeS0NKED2Ihiht6mzPSz6SvvqaSs";


// Url Encription
function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(error => {
        console.error(error.message);
      });
  }
  else {
    window.addLog('Service worker not support')
  }
}
