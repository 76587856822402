import { gql } from 'apollo-boost';
import {MediaImageFragment} from './images'

export const GetNewsListQuery = gql`
query newsList($page: Int, $pageSize: Int, $region: String, $category: String){
  newsList(page: $page, pageSize: $pageSize,filter: {field_region_target_id: $region, 
    field_category_target_id: $category}) {
    count
    results {
      id: nid
      title
      created
      publishedAt{
        date:value
      }
      backgroundImage:fieldBackgroundImage{
        entity{
          ...MediaImageFragment
        }
      }
      category: fieldCategory {
        entity {
          label: entityLabel
        }
      }
    }
  }
}

${MediaImageFragment}
`;

export const GetNewsDetailQuery = gql`
  query($id: String!) {
    result: nodeById(id: $id) {
      ... on NodeNews {
        id: nid
        title
        created
        publishedAt {
          date:value
        }
        image: fieldBackgroundImage {
          ... on FieldNodeNewsFieldBackgroundImage {
            entity {
              ...MediaImageFragment
            }
          }
        }
        content: body {
          value
          processed
          summary
          summaryProcessed
        }
      }
    }
  }

  ${MediaImageFragment}
`;
