import React from 'react';
export const SurveyCard = ({ entity }) => {
    return (
        <article className="document-card flex flex-col flex-1 pt-15 pb-15 b-b-white-dark">
            <h2 className="text-blue mb-5 font-bold pointer" onClick={() => {
                window.open(entity.uri, "_blank")
            }}>
                {entity.title}
            </h2>


        </article>
    );
};
