import React, { Component } from 'react';
import ErrorPage from "../../Pages/Error/ErrorPage";
import { log, reportLevel } from '@fci/business-layer/services';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            errorInfo: null
        };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        // log error messages to an error reporting service here
        log(
            reportLevel.error,
            error.toString(),
            {
                errorInfo
            }
        );
    }

    render() {
        if (this.state.errorInfo) {
            return <ErrorPage error={this.state.error} errorInfo={this.state.errorInfo}/>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
