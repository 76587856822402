import { gql } from 'apollo-boost';
export const GetEventCategories = gql`
query{
  data:taxonomyTermQuery(
    filter: {
      conditions: {
        field: "vid"
        value: "event_categories"
      }
    }
  ){
    list:entities{
      id:entityId
      label:entityLabel
    }
  }
}
`;

export const GetNewsCategories = gql`
query{
  data:taxonomyTermQuery(
    filter: {
      conditions: {
        field: "vid"
        value: "categories"
      }
    }
  ){
    list:entities{
      id:entityId
      label:entityLabel
    }
  }
}
`;

export const GetCourseCategories = gql`
query{
  data:taxonomyTermQuery(
    filter: {
      conditions: {
        field: "vid"
        value: "course_types"
      }
    },
    sort:{field:"name", direction:ASC}
  ){
    list:entities{
      id:entityId
      label:entityLabel
    }
  }
}
`;