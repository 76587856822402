import React from 'react';
import { IconAcademy, IconEvents, IconLibrary, IconMembers, IconNews, LinkBox } from "..";
import { Trans } from "react-i18next";

export const MainMenu = ({toggleMenu, className, sidebar}) => {
    return (
        <nav className={`flex flex-col items-center ${className ? className : ''}`}>
            <ul className="flex flex-wrap justify-between w-full max-w-230">
                <li className="w-48p mb-10" onClick={toggleMenu}>
                    <LinkBox
                        to={'/news'}
                        icon={<IconNews className={'icon-md'} />}
                        title={<Trans i18nKey="news">News</Trans>}
                        variant="sidebar"
                        exact={true}
                    />
                </li>

                <li className="w-48p mb-10" onClick={toggleMenu}>
                    <LinkBox
                        to={'/events'}
                        icon={<IconEvents className={'icon-md'}/>}
                        title={<Trans i18nKey="events">Events</Trans>}
                        variant="sidebar"
                        exact={true}
                    />
                </li>

                <li className="w-48p mb-10" onClick={toggleMenu}>
                    <LinkBox
                        to={'/members'}
                        icon={<IconMembers className={'icon-md'}/>}
                        title={<Trans i18nKey="members">Members</Trans>}
                        variant="sidebar"
                        exact={true}
                    />
                </li>

                <li className="w-48p" onClick={toggleMenu}>
                    <LinkBox
                        backgroundClass={ sidebar ? 'bg-violet-lighter' : 'bg-violet' }
                        textClass={'text-white'}
                        to={'/library'}
                        icon={<IconLibrary className={'icon-md'} />}
                        title={<Trans i18nKey="library">Library</Trans>}
                        label={<Trans i18nKey="membersOnly">MEMBERS ONLY</Trans>}
                        variant="sidebar"
                        exact={true}
                    />
                </li>

                <li className="w-48p" onClick={toggleMenu}>
                    <LinkBox
                        to={'/education'}
                        icon={<IconAcademy className={'icon-md'} />}
                        title={<Trans i18nKey="education">Education</Trans>}
                        variant="sidebar"
                        exact={true}
                    />
                </li>
            </ul>
        </nav>
    );
};
