import React, { useState, useEffect, useContext } from 'react';
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { IconMiniChatHeader, IconNotification } from "..";
import { Sidebar } from "./Sidebar";
import { GetCurrentUserDetail } from '@fci/business-layer/services/user';
import { withApollo } from 'react-apollo';
import storage from '@fci/business-layer/storage';
import { token } from '@fci/business-layer/constants';
import { GetTotalUnread } from '@fci/business-layer/services';
import AppContext from "./../../AppContext";
import networkHelpers from '@fci/business-layer/helpers/network-helpers';
import ChatContext from "../../Pages/Chat/ChatContext";

const Header = (props) => {
    let _isMounted = false;
    const location = useLocation();
    const [unreadNotification, setUnreadNotification] = useState(0);
    const [image, setImage] = useState('/assets/images/no-pict.png');
    const { InnerChatContext } = ChatContext;
    const { MenuContext } = AppContext;
    const chatContext = useContext(InnerChatContext);

    const menuProps = useContext(MenuContext);

    useEffect(() => {
        _isMounted = true;

        if (!storage.getItem(token)) return;

        props.client.query({
            query: GetCurrentUserDetail,
        }).then(n => {
            if (_isMounted) {
                if (n?.data?.user?.userPicture?.derivative?.url)
                    setImage(n?.data?.user?.userPicture?.derivative?.url)
            }
        });

        return () => {
            _isMounted = false;
        }
    }, [props.location.pathname, props.client])

    useEffect(() => {
        const interval = setInterval(async () => {
            if (networkHelpers.isOnline()) {
                try {
                    const n = await GetTotalUnread();
                    if (n.data.success)
                        setUnreadNotification(parseInt(n.data.total));
                } catch (e) {
                    console.log(e?.response?.data?.message ?? e)
                }
            }
        }, 5000);

        if (location.pathname === '/login') {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [location.pathname]);

    if (location.pathname === '/login') {
        return null;
    }

    return (
        <>
            <header id="header" className="flex flex-none items-center justify-between pr-20 pl-20 h-64 bg-white">
                <NavLink to="/">
                    <img src={require('@fci/business-layer/assets/fci-logo.svg')}
                        alt="FCI"
                        style={{ width: 88 }} />
                </NavLink>

                <div className="flex items-center justify-between">
                    {!!chatContext &&
                        <div className="text-blue text-2xl mr-30 badge-wrapper">
                            <NavLink to={'/chat'}>
                                <IconMiniChatHeader />
                                {!!chatContext.state.totalUnreadMessages
                                    ? <div
                                        className="bg-violet badge badge-md header">!</div>
                                    : ''
                                }
                            </NavLink>
                        </div>
                    }

                    <div className="text-blue text-2xl mr-30 badge-wrapper">
                        <NavLink to={'/notifications'}>
                            <IconNotification />
                            {!!unreadNotification
                                ? <div className="bg-violet badge badge-md header">!</div>
                                : ''
                            }
                        </NavLink>
                    </div>

                    <div className={`hamburger${menuProps.showMenu ? ' open' : ''}`} onClick={menuProps.showMenu ? menuProps.closeMenu : menuProps.openMenu}>
                        <span />
                        <span />
                        <span />
                    </div>
                </div>
            </header>

            <React.Suspense fallback={<div />}>
                <Sidebar toggleMenu={menuProps.closeMenu} image={image} />
            </React.Suspense>
        </>
    );
};


export default withRouter(withApollo(Header));
