let networkOnlineStatus = true;
export default {
    setOffline: () => {
        networkOnlineStatus = false;
    },
    setOnline: () => {
        networkOnlineStatus = true;
    },
    isOnline: () => {
        return networkOnlineStatus
    }
}