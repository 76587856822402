import React, { useState, useEffect } from "react";
import { useAddToHomescreenPrompt } from "../../hooks/useAddToHomescreenPrompt";
import { IconClose, LogoFavicon } from "..";
import { isMobile, isMobileOnly, isIOS, isSafari, isFirefox, isOpera } from 'react-device-detect';
import install_ios from '../../assets/images/install_ios.png';
import install_firefox from '../../assets/images/install_firefox.png';
import install_opera from '../../assets/images/install_opera.png';
import install_opera_2 from '../../assets/images/install_opera_2.png';

export function InstallPrompter() {
    const [prompt, promptToInstall] = useAddToHomescreenPrompt();
    const [isVisible, setVisibleState] = useState(false);
    const [showInstallGuide, setShowInstallGuide] = useState(false);
    const imgStyle = {
        display: 'inline',
        width: '20px',
        margin: '0 5px',
        verticalAlign: 'middle',
    };

    const hide = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setVisibleState(false);
    }

    const hideAndSetCookie = () => {
        setShowInstallGuide(false);

        document.cookie = "hideInstallGuide=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
    }

    useEffect(() => {
        if (!document.cookie.split('; ').find(row => row.startsWith('hideInstallGuide'))) {
            setShowInstallGuide(true);
        }
    }, []);

    useEffect(() => {
        if (prompt) {
            setVisibleState(true);
        }
    }, [prompt]);

    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    //Show pwa install guide
    if (isMobileOnly) {
        // Check ios
        if (isIOS && isSafari && !isInStandaloneMode() && showInstallGuide) {
            return <InstallDiv close={hideAndSetCookie}>
                <>To install this app on your phone, tap <img style={imgStyle} src={install_ios}
                                                              alt="Install on ios"/> and then add to homescreen
                </>
            </InstallDiv>
        }

        // Check mobile firefox
        if (isFirefox && showInstallGuide) {
            return <InstallDiv close={hideAndSetCookie}>
                <>To install this app on your phone, tap <img style={imgStyle} src={install_firefox}
                                                              alt="Install on mobile firefox"/> on the top of your
                    browser
                </>
            </InstallDiv>
        }

        // Check mobile opera
        if (isOpera && showInstallGuide) {
            return <InstallDiv close={hideAndSetCookie}>
                <>To install this app on your phone, tap <img style={imgStyle} src={install_opera}
                                                              alt="Install on mobile opera"/> on the top of your browser
                    and then add to homescreen <img style={imgStyle} src={install_opera_2}
                                                    alt="Install on mobile opera"/></>
            </InstallDiv>
        }
    }

    if (!isVisible) {
        return <div />;
    }

    return (
        <div className="flex justify-between position-fixed z-9999 bottom-0 left-0 shadow-top w-full items-center pr-20 pl-20 h-64 bg-grey-dark text-blue-light font-semibold"
             onClick={promptToInstall}>
            <div className="flex items-center">
                <LogoFavicon className="text-2xl mr-10" /> Add FCI to Home screen
            </div>

            <button className="bg-none p-5"
                    type="button"
                    onClick={hide}><IconClose className="text-white"/></button>
        </div>
    );
}

const InstallDiv = ({children, close}) => {
    return (
        <div className="flex justify-between position-fixed z-9999 bottom-0 left-0 shadow-top w-full items-center pt-5 pb-5 pr-20 pl-20 min-h-64 bg-grey-dark text-blue-light font-semibold">
            <div className="flex items-center">
                <LogoFavicon className="text-2xl mr-10" /> <div className="leading-xl">{children}</div>
            </div>

            <button className="bg-none p-5"
                    type="button"
                    onClick={close}><IconClose className="text-white"/></button>
        </div>
    );
};
