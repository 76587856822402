import React, { useContext } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IconChatCTA } from "..";
import AppContext from "./../../AppContext"
const { MenuContext } = AppContext

export const Footer = () => {
    const { t } = useTranslation();
    const location  = useLocation();
    const menuProps = useContext(MenuContext);


    if (location.pathname === '/login' || location.pathname === '/chat') {
        return null;
    }

    return (
        <footer id="footer" onClick={menuProps.closeMenu}>
            <div className="footer-chat-cta-wrap"/>

            <NavLink className="footer-chat-cta" to="/chat" exact={true}>
                <IconChatCTA className="footer-chat-cta-icon"/>

                <span className="text-sm text-white font-semibold">{t('startChatting')}</span>
            </NavLink>
        </footer>
    );
};
