import React from 'react';
import { InnerWrapper } from "../../components";
import { useTranslation } from "react-i18next";

const ErrorPage = ({error, errorInfo}) => {
    const { t } = useTranslation();
    
    return (
        <div className="p-20">
            <InnerWrapper>
                <h2 className="text-lg font-semibold text-center">
                    {t('errorPageMessage')}
                </h2>

                {process.env.NODE_ENV !== "production" &&
                    <details style={{whiteSpace: 'pre-wrap'}}>
                        {error && error.toString()}
                        <br/>
                        {errorInfo.componentStack}
                    </details>
                }
            </InnerWrapper>
        </div>
    );
};

export default ErrorPage;
