import * as React from "react";

export function useAddToHomescreenPrompt() {
    const [prompt, setState] = React.useState(
        null
    );

    const promptToInstall = (e) => {
        e.preventDefault();
        if (prompt) {
            return prompt.prompt();
        }
        return Promise.reject(
            new Error(
                'Tried installing before browser sent "beforeinstallprompt" event'
            )
        );
    };

    React.useEffect(() => {
        let installChecker = null;
        if (window.installPrompter) {
            setState(window.installPrompter)
        } else {
            installChecker = setInterval(() => {
                console.log('Checking installer')
                if (window.installPrompter) {
                    setState(window.installPrompter)
                    clearInterval(installChecker)
                }
            }, 1000);
        }
        return () => {
            clearInterval(installChecker)
        }
    }, []);

    return [prompt, promptToInstall];
}