import React from 'react';
import { NavLink } from "react-router-dom";

export const LinkBox = ({to, icon, title, label, variant, backgroundClass, textClass}) => {
    let iconColorClass = 'text-white';

    return (
        <NavLink
            className={`square-box flex rounded ${backgroundClass}`}
            to={to}
            exact={true}>
            <div className="inner p-5 flex flex-col justify-center items-center">
                {
                    label ? (<h3 className={`text-xxs ${textClass  } font-bold text-center mb-5`}>{label}</h3>) : (<h3 className={`text-xxs text-transparent font-bold text-center mb-5`}>MEMBERS</h3>)
                }
                <div className={`${iconColorClass} text-center text-5xl mb-5`}>
                    { icon }
                </div>

                <h3 className={`text-sm ${textClass} font-semibold text-center`}>{title}</h3>
            </div>
        </NavLink>
    );
};

LinkBox.defaultProps = {
    backgroundClass: 'bg-violet-dark',
    textClass: 'text-blue-light'
}
