import moment from 'moment'
import { GetFullName, GetCompanyCountryCode, GetCompanyName, GetCompanyCountry } from '../services/user';
export const groupProgramByDate = (programs) => {
    const dayPrograms = {};
    for (let i = 0; i < programs.length; i++) {
        const program = programs[i]?.entity;
        if (program?.date?.value) {
            const day = program?.date?.value; //moment(program?.date?.value ?? 0, "YYYY-MM-DD").utc().format('dddd DD.MM.YYYY')
            if (!dayPrograms[day]) {
                dayPrograms[day] = [];
            }
            const newProgram = {
                content: program?.description?.processed ?? "",
                title: program?.title ?? "",
                from: moment.unix(program?.from).utc().format('HH:mm'),
                to: moment.unix(program?.to).utc().format('HH:mm'),
                speakers: (program?.speakers ?? []).map((item, index) => {
                    return {
                        key: index,
                        ...item.entity
                    }
                }),
                documents: (program?.documents ?? []).map((item, index) => {
                    return {
                        key: index,
                        ...item.entity
                    }
                })
            }
            dayPrograms[day].push(newProgram);
        }
    }
    return dayPrograms
}

export const getSpeakersFromProgram = (programs, filters) => {
    const speakers = {};
    const countries = {};

    for (let i = 0; i < programs.length; i++) {
        const program = programs[i]?.entity;
        const programSpeakers = program?.speakers ?? [];
        for (let j = 0; j < programSpeakers.length; j++) {
            const speaker = programSpeakers[j].entity;
            if (filters) {
                const {
                    name: filterName,
                    country: filterCountry
                } = filters;
                if (filterCountry && filterCountry !== "") {
                    if (!(GetCompanyCountryCode(speaker) ?? "").toLowerCase().includes(filterCountry.toLowerCase())) {
                        continue;
                    }
                }

                if (filterName && filterName !== "") {
                    if (
                        !GetFullName(speaker).toLowerCase().includes(filterName.toLowerCase()) &&
                        !(GetCompanyName(speaker) ?? "").toLowerCase().includes(filterName.toLowerCase()) &&
                        !(speaker?.jobTitle ?? "").toLowerCase().includes(filterName.toLowerCase())
                    ) {
                        continue;
                    }
                }
            }
            if (speaker) {
                if (!speakers[speaker.id]) {
                    speakers[speaker.id] = {
                        ...speaker,
                        programs: []
                    };
                }
                speakers[speaker.id].programs.push({
                    date: moment(program?.date?.value ?? 0, "YYYY-MM-DD").format('dddd DD.MM'),
                    content: program?.description?.processed ?? "",
                    title: program?.title,
                    from: moment.unix(program?.from).utc().format('hh:mm A'),
                    to: moment.unix(program?.to).utc().format('hh:mm A'),
                });
                if (GetCompanyCountryCode(speaker)) {
                    countries[GetCompanyCountryCode(speaker)] = {
                        value: GetCompanyCountryCode(speaker),
                        label: GetCompanyCountry(speaker)
                    }
                }
            }
        }
    }
    
    return {
        speakers, 
        countries: Object.keys(countries).map(key => countries[key])
    };
}