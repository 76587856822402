import React from 'react';
import parse from 'html-react-parser';
import { GetFullName } from '@fci/business-layer/services/user';
import { IconPaper } from '../Icons/Icons';
import { getSpeakersFromProgram } from '@fci/business-layer/helpers/program-helper';
function ProgramSpeakerComponent(props) {
    const { speaker } = props;
    return (
        <li className={`li-disc ms-15  ${speaker.biography ? ' pointer font-semibold text-blue-light' : ''} `} onClick={props.onClick}>
            <h5 className="mb-5">
                {GetFullName(speaker)}
            </h5>
        </li>
    )
}

function ProgramDocumentComponent(props) {
    const { document } = props;

    const toDownload = {
        name: '',
        url: ''
    }

    const download = (url) => {
        if (url) {
            window.open(url, "_blank")
        }
    }

    if (document.document) {
        toDownload.name = document?.document?.entity?.filename;
        toDownload.url = document?.document?.entity?.url;
    } else {
        toDownload.name = document?.fieldMediaImage?.entity?.filename;
        toDownload.url = document?.fieldMediaImage?.entity?.url;
    }
    return (
        <span className="pointer"
            onClick={() => download(toDownload.url)} >
            <h5 className="mb-10"><IconPaper />  {toDownload.name}</h5>
        </span>
    )
}
export const ProgramCard = ({ program, onClick, programs, currentDay }) => {
    if (!program) {
        return null;
    }

    const _onClick = (item) => {
        const _programs = getSpeakersFromProgram(programs);
        if (item.biography)
            onClick(_programs.speakers[item.id], 'program', currentDay);
    }
    const speakerComponents = program.speakers.map((item, index) => <ProgramSpeakerComponent programs={programs} key={`speaker-${index}`} onClick={() => {
        _onClick(item)
    }} speaker={item} />)
    const documentComponents = program.documents.map((item, index) => <ProgramDocumentComponent key={`document-${index}`} document={item} />);

    return (
        <div className="program-card mb-10">
            <h2 className="font-semibold text-blue-light mt-20 mb-20">
                {`${program?.from} > ${program?.to}`}
            </h2>

            <h2 className="text-grey-light mb-10">
                {`${program?.title}`}
            </h2>

            {parse(program?.content ?? '')}

            <ul className="mb-10 mt-10">
                {
                    program.speakers && program.speakers.length > 0 &&
                    (speakerComponents)
                }
            </ul>
            {
                program.documents && program.documents.length > 0 &&
                (documentComponents)
            }
        </div>
    );
};
