import React from 'react';
import { Trans } from "react-i18next";

export const NoData = ({ content, message }) => {
    return (
        <>
            <h2 className="text-md text-blue font-semibold mb-5 text-center">
                {message ? message : <Trans i18nKey="noData" count={content} >
                    No {{ content }} found within the current criteria
                </Trans>
                }
            </h2>
        </>
    );
};
