import React, { useState, useEffect } from 'react'
import { IconWechat, IconShare, IconFacebookFullColor, IconTwitterFullColor, IconLinkedInFullColor } from '../Icons/Icons';
import { useTranslation } from 'react-i18next';

export const ShareContent = ({ className, title, url }) => {
    const [supportBrowser, setSupportBrowser] = useState(false)
    const { t } = useTranslation();

    useEffect(() => {
        if (navigator.share) {
            setSupportBrowser(true)
        } else {
            setSupportBrowser(false)
        }
    }, [])

    const shareContent = () => {
        if (navigator.share && supportBrowser) {
            navigator.share({
                title: title,
                url: url
            }).then(() => {
                console.log('Thanks for sharing!');
            })
                .catch(console.error);
        }
    }

    let encodeUrl = encodeURI(url)
    let encodeTitle = encodeURI(title)

    const urlFacebook = "https://www.facebook.com/sharer/sharer.php?u=" + encodeUrl
    const urlTwitter = "http://twitter.com/share?url=" + encodeUrl + "&text=" + encodeTitle
    const urlLinkedIn = "https://www.linkedin.com/sharing/share-offsite/?url=" + encodeUrl
    const urlWechat = "https://www.e-mailit.com/ext/wechat/share/#url=" + encodeUrl

    return (
        <div>
            { supportBrowser && <div className="flex" style={{alignItems:"center"}} onClick={() => shareContent()}>
                    <div className={`text-blue-light uppercase ${className}`} style={{ fontSize: 10,fontWeight:"bold" }}>{t('share')}</div> 
                    <div className={className} style={{ marginLeft: 9}}><IconShare /></div>
                </div>}
            { !supportBrowser &&
                <div>
                    <div className="flex" style={{ marginBottom: 20,alignItems:"center" }}>
                        <div className={`text-blue-light uppercase`} style={{ fontSize: 10,fontWeight:"bold", marginRight:9 }}>{t('share')}</div> 
                        <a href={urlFacebook} target="_blank" rel="noopener noreferrer">
                            <div className="flex" style={{ flexDirection: "column", marginRight: 10 }}>
                                <IconFacebookFullColor width="15pt" height="15pt" />
                            </div>
                        </a>
                        <a href={urlTwitter} target="_blank" rel="noopener noreferrer">
                            <div className="flex" style={{ flexDirection: "column", marginRight: 10 }}>
                                <IconTwitterFullColor width="15pt" height="15pt" />
                            </div>
                        </a>
                        <a href={urlLinkedIn} target="_blank" rel="noopener noreferrer">
                            <div className="flex" style={{ flexDirection: "column", marginRight: 10 }}>
                                <IconLinkedInFullColor width="15pt" height="15pt" />
                            </div>
                        </a>
                        <a href={urlWechat} target="_blank" rel="noopener noreferrer">
                            <div className="flex" style={{ flexDirection: "column", marginRight: 10 }}>
                                <IconWechat width="15pt" height="15pt" />
                            </div>
                        </a>
                    </div>
                </div>
            }
        </div>
    )
}
