import React, { Component } from 'react';
import { Button } from '../Form/Button';
import { Trans } from 'react-i18next';
import { InnerWrapper } from "..";
import BackLinkProfile from "../../Pages/Profile/BackLinkProfile";

export class CaptureCamera extends Component {
    /**
     * Processes available devices and identifies one by the label
     * @memberof CameraFeed
     * @instance
     */
    processDevices(devices) {
        devices.forEach(device => {
            this.setDevice(device);
        });
    }

    /**
     * Sets the active device and starts playing the feed
     * @memberof CameraFeed
     * @instance
     */
    async setDevice(device) {
        const { deviceId } = device;

        this.videoPlayer.srcObject = await navigator.mediaDevices.getUserMedia({
            audio: false, video: { deviceId }
        });

        this.videoPlayer.onloadedmetadata = (e) => {
            this.videoPlayer.play();
        };
    }

    /**
     * On mount, grab the users connected devices and process them
     * @memberof CameraFeed
     * @instance
     * @override
     */
    async componentDidMount() {
        const cameras = await navigator.mediaDevices.enumerateDevices();

        this.processDevices(cameras);
    }

    /**
     * Handles taking a still image from the video feed on the camera
     * @memberof CameraFeed
     * @instance
     */
    takePhoto = () => {
        const context = this.canvas.getContext('2d');
        context.drawImage(this.videoPlayer, 0, 0, this.videoPlayer.videoWidth, this.videoPlayer.videoHeight);

        this.props.onTakePicture({
            file: this.canvas.toDataURL()
        });

        const tracks = this.videoPlayer.srcObject.getTracks();
        tracks.forEach(track => track.stop());
        this.videoPlayer.srcObject = null;
    };

    cancel = () => {
        const tracks = this.videoPlayer.srcObject.getTracks();
        tracks.forEach(track => track.stop());
        this.videoPlayer.srcObject = null;

        this.props.onTakePicture({
            file: undefined
        });

        // Camera is not turning off?
        window.location.reload();
    }

    render() {
        return (
            <>
                <BackLinkProfile onCLick={this.cancel} title={<Trans i18nKey="yourProfile">Your Profile</Trans>}/>

                <div className="pl-20 pr-20 pt-20 pb-10 flex flex-col flex-1">
                    <InnerWrapper className="flex flex-col flex-1">
                        <div className="c-camera-feed">
                            <div className="flex  flex-col pl-20 pr-20 items-center justify-center">
                                <div className="flex flex-col items-center">
                                    <Button className="mb-10 w-234" variant="violet" onClick={this.takePhoto} >
                                        <Trans i18nKey="takePicture">Take Picture</Trans>
                                    </Button>
                                    {/*<Button className="mb-20 w-234" onClick={this.cancel} >*/}
                                    {/*    <Trans i18nKey="cancel">Cancel</Trans>*/}
                                    {/*</Button>*/}
                                </div>

                                <div className="c-camera-feed__viewer">
                                    <video ref={ref => (this.videoPlayer = ref)} preload="none" width="100%" />
                                </div>

                                <div className="c-camera-feed__stage" style={{ display: 'none' }}>
                                    <canvas width="680" height="360" ref={ref => (this.canvas = ref)} />
                                </div>
                            </div>
                        </div>
                    </InnerWrapper>
                </div>
            </>
        );
    }
}
