import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConvertFileSize } from '@fci/business-layer/helpers/file-helper';
import { IconDownload } from '../Icons/Icons';
import { fileDownload } from "../../utils";

export const DocumentCard = ({ entity }) => {
    const fileName = entity?.document?.entity?.filename;
    const [downloading, setDownloading] = useState(false);
    const { t } = useTranslation();
    const size = ConvertFileSize(entity?.document?.entity?.filesize ?? 0);
    const extension = (fileName ? fileName.split('.').pop() : "");

    const handleFileDownload = (url) => {
        setDownloading(true);

        fileDownload(url, () => {
            setDownloading(false);
        });
    }

    return (
        <article className="document-card flex flex-row justify-between items-center flex-1 b-b-white-dark pb-15 pt-15">
            <div className="flex flex-col mr-10">
                <button className="bg-none text-left text-blue mb-5 font-bold break-word"
                        onClick={() => {
                            handleFileDownload(entity.document.entity.url);
                        }}
                        disabled={downloading}>
                    {fileName}
                </button>

                <h2 className="text-grey-light mb-5">
                    {`${extension.toUpperCase()} ${t('document')}, ${size}`}
                </h2>
            </div>

            <button className="bg-download-document pointer rounded pl-20 pr-20 pt-15 pb-15"
                    onClick={() => {
                        handleFileDownload(entity.document.entity.url);
                    }}
                    disabled={downloading}
            >
                <IconDownload className="text-blue-light" />
            </button>
        </article>
    );
};
