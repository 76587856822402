import React, { useEffect, useState } from 'react';
import { Avatar, IconArrowLong, IconFacebook, IconLinkedin, IconTwitter, MainMenu } from "..";
import { Trans } from "react-i18next";
import { NavLink } from "react-router-dom";
import { BASE_URL } from '@fci/business-layer/constants';
import { settingConfiguration } from "@fci/business-layer/storage";
import { EventEmitter } from "../../utils";

const app_url = settingConfiguration.get(BASE_URL);

export const Sidebar = (props) => {
    const { toggleMenu, image } = props;
    const [userImage, setUserImage] = useState(null);
    const socialClass = 'flex flex-1 items-center justify-center b-social text-white rounded pt-15 pb-15';

    useEffect(() => {
        EventEmitter.on('update-user-image', (image) => setUserImage(image));
    }, []);

    return (
        <aside id="sidebar" className="sidebar flex position-relative">
            <div className="sidebar-inner-wrap flex flex-col flex-1 p-30">
                <div className="flex justify-end mb-20">
                    <NavLink className="flex justify-between items-center"
                        to="/profile"
                        exact={true}
                        onClick={toggleMenu}>
                        <h2 className="text-sm font-semibold text-white mr-20">
                            {<Trans i18nKey="myProfile">My profile</Trans>}
                        </h2>

                        <Avatar url={userImage ? userImage : image} />
                    </NavLink>
                </div>

                <MainMenu className="flex-1" toggleMenu={toggleMenu} sidebar />

                <div className="position-absolute bottom-20">
                    <a href={`${app_url}/newsletter`}
                        className="inline-block font-bold text-white mb-20"
                        target="_blank"
                        rel="noopener noreferrer">
                        <Trans i18nKey="stayUpdated">
                            Stay updated on FCI News <br /> and Publications
                        </Trans> <IconArrowLong className="ml-5 text-xxs text-orange rotate-180" />
                    </a>
                    <a href={`${app_url}en/app-disclaimer `}
                        className="inline-block font-bold text-white mb-20"
                        target="_blank"
                        rel="noopener noreferrer">
                        <Trans i18nKey="legalDisclaimer">
                            Legal disclaimer
                        </Trans><IconArrowLong className="ml-5 text-xxs text-orange rotate-180" />
                    </a>

                    <div className="flex items-center justify-between">
                        <a className={`${socialClass} mr-10`}
                            href="https://www.linkedin.com/company/FCI-factoring"
                            target="_blank"
                            rel="noopener noreferrer">
                            <IconLinkedin />
                        </a>

                        <a className={`${socialClass} mr-10`}
                            href="https://www.facebook.com/FCIfactoring/"
                            target="_blank"
                            rel="noopener noreferrer">
                            <IconFacebook />
                        </a>

                        <a className={`${socialClass}`}
                            href="https://twitter.com/fci_factoring"
                            target="_blank"
                            rel="noopener noreferrer">
                            <IconTwitter />
                        </a>
                    </div>
                </div>
            </div>
        </aside>
    );
};
