import Axios from '@fci/business-layer/helpers/axios-helpers';
import { settingConfiguration } from "@fci/business-layer/storage";
import { BASE_URL } from "@fci/business-layer/constants";

export const fileDownload = (url, callback) => {
    Axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // important
    })
    .then((response) => {
        const filename = decodeURI(response.config.url.substring(url.lastIndexOf('/')+1));
        const blob = new Blob([response.data]);

        fileHelper(blob, filename);

        if (callback) {
            callback();
        }
    })
    .catch(error => {
        if (callback) {
            callback();
        }
    });
};

export const fileDownloadById = (id, label, callback) => {
    Axios({
        url: `${settingConfiguration.get(BASE_URL)}en/media/${id}/download`,
        method: 'GET',
        responseType: 'blob', // important
    })
        .then((response) => {
            const blob = new Blob([response.data]);

            fileHelper(blob, label);

            if (callback) {
                callback();
            }
        })
        .catch(error => {
            if (callback) {
                callback();
            }
        });
}

const fileHelper = (blob, filename) => {
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, filename);
    } else {
        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);

        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        // Fixes "webkit blob resource error 1"
        setTimeout(function() {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }, 0)
    }
};
