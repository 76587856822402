import React, {useContext} from 'react';
import AppContext from "./../../AppContext"
const { MenuContext } = AppContext

export const AuthLayout = (props) => {

    const menuProps = useContext(MenuContext);
    return (
        <main className="main-auth" onClick={menuProps.closeMenu}>
            {props.children}
        </main>
    );
};
