let storage;
let config;

export const init = (library, configuration) => {
    storage = library;
    config = configuration
};

export const setItem = (key, object) => {
    storage.setItem('create_' + key, new Date().getTime() + '');
    storage.setItem(key, JSON.stringify(object));
};
export const setItemNative = async (key, object) => {
    await storage.setItem('create_' + key, new Date().getTime() + '');
    await storage.setItem(key, JSON.stringify(object));
};
export const setItemAsync = async (key, object) => {
    if (storage.setItemAsync)
        storage.setItemAsync(key, JSON.stringify(object));
    else {
        setItem(key, object);
    }
};

export const getItem = (key) => {
    if (storage.getItem(key))
        return JSON.parse(storage.getItem(key));
    return undefined;
};
export const removeItem = (key) => {
    storage.removeItem(key);
};

export const getItemAsync = async (key) => {
    const item = await storage.getItem(key);

    return JSON.parse(item);
};

export const creationKey = (key) => {
    return new Date(parseInt(storage.getItem('create_' + key)));
};

export const creationKeyAsync = async (key) => {
    const item = await storage.getItem('create_' + key);
    return new Date(parseInt(item));
};

export const settingConfiguration = {
    get: (name) => {
        if (config) {
            return config[name];
        }

        return undefined;
    }
};

export default {
    init: init,
    setItem: setItem,
    setItemAsync: setItemAsync,
    setItemNative: setItemNative,
    removeItem: removeItem,
    getItem: getItem,
    getItemAsync: getItemAsync,
    creationKeyAsync: creationKeyAsync
};
