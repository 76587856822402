import React from 'react';
import { IconArrowLong } from "..";

export const BackButtonTitleMyEvents = ({title, page, setActivePage, noBorder}) => {
    if (!title && !setActivePage && !page) {
        return null;
    }

    return (
        <div className={`flex items-center h-56 font-bold text-sm ml-20 mr-20 pt-40 pb-40 ${ !noBorder && 'b-back-btn'}`  }>
            <div className="flex items-center pointer leading" onClick={() => setActivePage(page)}>
                <IconArrowLong className="text-violet text-xs mr-15"/>
                <span className="text-blue text-3xl">{title}</span>
            </div>
        </div>
    );
};
