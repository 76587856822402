import React, { useEffect, useState } from 'react';
import { Redirect, Route } from "react-router-dom";
import { withTracker } from "../../hoc/withTracker";
import storage from '@fci/business-layer/storage';
import { token } from '@fci/business-layer/constants';
import { AuthLayout, PublicLayout } from "..";
import { GetCurrentUserDetail } from "@fci/business-layer/services/user";
import { withApollo } from "react-apollo";

const getUserAuth = () => {
    return storage.getItem(token);
};
const RouteWrapper = ({
    component: Component,
    isPrivate,
    ...rest
}) => {
    let _isMounted = false;
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState(true);
    const isAuth = getUserAuth();

    useEffect(() => {
        _isMounted = true;
        if (!isAuth) return;

        rest.client.query({
            query: GetCurrentUserDetail,
        }).then(res => {
            if (res && _isMounted) {
                setHasAcceptedTerms(res.data.user.hasAcceptCondition);
            }
        });

        return () => {
            _isMounted = false;
        }
    }, [rest.path, rest.client]);

    /**
     * Redirect the user to the Login page if it is not authorized,
     * or Redirect the user after login to the terms page if the terms are not accepted
     *
     */
    if (isPrivate && (!isAuth || !hasAcceptedTerms)) {
        return <Redirect to="/login" />;
    }

    if (!isPrivate && !!isAuth && !!hasAcceptedTerms) {
        return <Redirect to="/" />;
    }

    const Layout = (!!isAuth && !!hasAcceptedTerms) ? AuthLayout : PublicLayout;

    return (
        <Route
            {...rest}
            render={props => (
                <Layout>
                    <Component {...props}
                               setHasAcceptedTerms={setHasAcceptedTerms}
                               hasAcceptedTerms={hasAcceptedTerms}/>
                </Layout>
            )}
        />
    );
}
export default withTracker(withApollo(RouteWrapper));

RouteWrapper.defaultProps = {
    isPrivate: false,
};
