import React from 'react';
import { NavLink } from "react-router-dom";
import { IconTriangle } from "..";
import { Trans } from "react-i18next";

export const BackButtonList = ({to, title}) => {
    const titleInner = title ? title : <Trans i18nKey="backToList">Back to list</Trans>;

    return (
        <div className="flex items-center h-46 bg-blue-light font-bold text-sm text-white pl-20 pr-20">
            <NavLink to={to} exact={true}>
                <IconTriangle className="text-blue-lightest mr-5"/>
                <span className="text-white">{titleInner}</span>
            </NavLink>
        </div>
    );
};
