import { gql } from 'apollo-boost';
export const GetRegions = gql`
query{
  data:taxonomyTermQuery(
    filter: {
      conditions: {
        field: "vid"
        value: "regions"
      }
    }
  ){
    list:entities{
      id:entityId
      label:entityLabel
    }
  }
}
`;