import React from 'react';
import { IconClose } from "..";
import parse from 'html-react-parser';

export const NotificationCard = ({ notification, className, onClose }) => {
    return (
        <article className={`notification flex flex-col flex-1 text-sm bg-white rounded shadow p-20 ${className ? className : ''}`}>
            <h2 className="text-violet font-bold mb-5 pr-10">{notification.event_name ? notification.event_name : notification.title}</h2>
            {notification.event_name ? <h3 className="font-bold mb-5">{notification.title}</h3> : ""}

            <div>{parse(notification?.message ?? "")}</div>

            <button className="notification-close" onClick={() => onClose(notification)}><IconClose /></button>
        </article>
    );
};
