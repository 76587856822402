let subscribers = {};
export default {
    publish:(event, message)=> {
        console.log(`Prepare publish ${event} message : ${message}`);
        //waiting UI / listener Up
        setTimeout(() => {
            if (subscribers[event]) {
                console.log(`Broadcast event ${event} total ${subscribers[event].length} listener`);
                subscribers[event].map(function (item) {
                    item(message != undefined ? message : {});
                });
            }

        }, 2000)

    },
    subscribe:  (event, listener) =>{
        if (!subscribers[event]) {
            subscribers[event] = [];
        }
        const index = subscribers[event].push(listener) - 1;
        return {
            remove: function () {
                delete subscribers[event][index];
            }
        };
    }
};