export const token = "1ZxgOGsrcE";
export const media_id = "7ieAsd493";
export const device_id = "8sadI123";
export const BASE_URL = "API_URL";
export const NETWORK_TIMEOUT = "NETWORK_TIMEOUT";
export const NETWORK_ERROR = "NETWORK_ERROR";
export const CLIENT_ID = "CLIENT_ID";
export const CLIENT_SECRET = "CLIENT_SECRET";
export const BASE_URL_LANGUAGE = "API_LANGUAGE_URL";
export const REACT_APP_URL = "REACT_APP_URL";
export const REACT_APP_MIX_PUSHER_APP_KEY = "REACT_APP_MIX_PUSHER_APP_KEY";
export const REACT_APP_MIX_PUSHER_LOCATION = "REACT_APP_MIX_PUSHER_LOCATION";
export const REACT_APP_MIX_AUTH_LOCATION = "REACT_APP_MIX_AUTH_LOCATION";
export const LIBRARY_ABOUT_COLLAPSED = "LIBRARY_ABOUT_COLLAPSED"
export const MEMBERS_MAP_API_KEY = "MEMBERS_MAP_API_KEY"
export const GA_ACCOUNT="GA_ACCOUNT";
export const UNAUTHORIZE="UNAUTHORIZE";
export const PUSH_NOTIF_CLICKED="PUSH_NOTIF_CLICKED";
export const PUSH_NOTIF_TOKEN = "PUSH_NOTIF_TOKEN";
export const TOTAL_UNREAD_NOTIF = "TOTAL_UNREAD_NOTIF";
export const IS_RESTARTING = "IS_RESTARTING";
export const SHOULD_DISPLAY_ASK_QUESTION = true;
export const MAX_ATTACHMENT_SIZE = "MAX_ATTACHMENT_SIZE";
