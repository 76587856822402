import React, { useState, useEffect, useCallback,useRef } from "react";
import { useQuery } from '@apollo/react-hooks';
import { Loader } from "..";

export const InfiniteScroll = (props) => {
    const isMounted = useRef(true);
    const { query, variables } = props.query;
    const [completed, setCompleted] = useState(true);
    const { loading, data, fetchMore, refetch } = useQuery(query, variables);

    useEffect(() => {
        if (completed)
            refetch();
    }, [variables, refetch]);

    const onCompleted = useCallback((items) => {
        setCompleted(true);
        isLoading = false;
        return items;
    }, []);
    let isLoading = false;
    const handleOnScroll = useCallback(() => {
        if (isMounted.current) {
            let scrollTop =
                (document.documentElement && document.documentElement.scrollTop) ||
                document.body.scrollTop;
            let scrollHeight =
                (document.documentElement && document.documentElement.scrollHeight) ||
                document.body.scrollHeight;
            let clientHeight =
                document.documentElement.clientHeight || window.innerHeight;
            // Added 100 to accommodate on mobile when address bar is hidden
            let scrolledToBottom = Math.ceil(scrollTop + clientHeight) + 100 >= scrollHeight;

            if (scrolledToBottom) {
                if (loading === false)
                    if (props.haveMorePage === true && completed === true && isLoading === false) {
                        isLoading = true;
                        setCompleted(false);
                        props.onLoadMore({ fetchMore, data, onCompleted });
                    }
            }
        }
    }, [data, props, fetchMore, onCompleted, isMounted]);

    useEffect(() => {
        isMounted.current = true;
        document.addEventListener('scroll', handleOnScroll);

        return () => {
            document.removeEventListener('scroll', handleOnScroll);
            isMounted.current = false;

        };
    }, [data, loading, handleOnScroll]);

    return (
        <>
            {props.onRender({ loading, data })}
            {completed === true ? <></> : <Loader />}
        </>
    );
};
