import axios from 'axios'
import pubSub from '../helpers/pub-sub';
import { NETWORK_TIMEOUT, token, NETWORK_ERROR, UNAUTHORIZE } from '../constants';
import storage, { settingConfiguration, creationKeyAsync } from '../storage';
import networkHelpers from './network-helpers';
import { Base64 } from 'js-base64'
import { refreshTokenAsync } from '../services/auth';

const getTimeout = () => {
    return settingConfiguration.get(NETWORK_TIMEOUT) ? settingConfiguration.get(NETWORK_TIMEOUT) : 300000;
}

axios.interceptors.request.use(async (config) => {
    if (!config.skipHeader) {
        let accessToken = await storage.getItemAsync(token);
        if (accessToken) {
            const creation = await creationKeyAsync(token);
            creation.setSeconds(creation.getSeconds() + accessToken.expires_in)
            const different = creation - new Date();
            //refresh token if less that 10 minutes
            if (different <= 10 * 60 * 1000) {
                await refreshTokenAsync();
                accessToken = await storage.getItemAsync(token)
            }
            config.headers['Authorization'] = "Bearer " + accessToken.access_token;
            config['Content-Type'] = 'application/json';
        }
        if (!config.timeout || config.timeout == 0)
            config.timeout = getTimeout();
    }
    return config;
});
const errorHandler = async (error) => {
    try {
        if (networkHelpers.isOnline() === false) {
            const key = Base64.btoa(error?.config?.url + JSON.stringify(error?.config?.data));
            const data = await storage.getItemAsync(key);
            console.log(`Read Data ${key}`);
            if (data) {
                console.log(`Found Data ${Base64.decode(data)}`);
                return Promise.resolve({ data: JSON.parse(Base64.decode(data)) })

            } else {
                pubSub.publish(NETWORK_ERROR, error);
            }
        } else {
            if (error.message && error.message.indexOf('timeout of') >= 0) {
                pubSub.publish(NETWORK_TIMEOUT, error);
            }
            else {
                pubSub.publish(NETWORK_ERROR, error);
            }
        }
        if (error?.response?.status === 401) {
            pubSub.publish(UNAUTHORIZE, error);
        }

        return Promise.reject({ ...error })
    } catch (error) {
        console.log(error)
        return Promise.reject({ ...error })
    }
}

const successHandler = async (response) => {
    try {
        const key = Base64.encode(response.config.url + JSON.stringify(response.config.data))
        const value = Base64.encode(JSON.stringify(response.data));
        await storage.setItemAsync(key, value);
    } catch (e) {
        console.log(e)
    }
    return response
}
axios.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
)
export default axios;