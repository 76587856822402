import React, { useEffect } from 'react';
import { SentEvent } from '../../hoc/withTracker';

export const AdCard = ({ className, ads }) => {
    useEffect(() => {
        if (ads)
            SentEvent({
                category: 'Advertising',
                action: 'display',
                label: ads?.code,
                value: ads?.code
            });
    }, [ads]);

    if (!ads) {
        return null;
    }

    const onClick = () => {
        if (ads) {
            if (ads?.link?.uri) {
                window.open(ads?.link?.uri);
                SentEvent({
                    category: 'Advertising',
                    action: 'click',
                    label: ads?.code,
                    value: ads?.code
                });
            }
        }
    }

    let adBackImg = ads?.image?.entity?.fieldMediaImage?.entity?.url
        ? { backgroundImage: `url(${ads?.image?.entity?.fieldMediaImage?.entity?.url})` }
        : null;

    return (
        <article
            className={`flex flex-col justify-center items-center flex-1 text-sm h-160 bg-cover w-full bg-advertising rounded shadow p-20 pointer ${className ? className : ''}`}
            style={adBackImg}
            onClick={onClick}>
            <h2 className="text-center text-white text-base font-semibold tracking-xl">{ads?.label ?? 'ADVERTISING BANNER'}</h2>
        </article>
    );
};
