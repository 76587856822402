import storage, { creationKey, settingConfiguration, creationKeyAsync } from '../storage'
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { withClientState } from 'apollo-link-state';
import { ApolloLink, Observable } from 'apollo-link';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { refreshToken, initlizeEndpoint, refreshTokenAsync } from './auth'
import { token, BASE_URL, NETWORK_TIMEOUT, NETWORK_ERROR } from '../constants'
import pubSub from '../helpers/pub-sub';
import { log, reportLevel } from "./reporting";
const localStorage = storage;
const getTimeout = () => {
    return settingConfiguration.get(NETWORK_TIMEOUT) ? settingConfiguration.get(NETWORK_TIMEOUT) : 300000;
}
export const apiClient = ({ cache, onUnauthorized }) => {
    const apiUrl = settingConfiguration.get(BASE_URL) ? settingConfiguration.get(BASE_URL) + 'graphql' : '';
    if (apiUrl === '')
        throw "API URL UNDEFINED"

    initlizeEndpoint();

    const request = async (operation) => {
        const currentToken = (localStorage.getItem(token))

        if (currentToken && currentToken.access_token) {
            const creation = creationKey(token);

            creation.setSeconds(creation.getSeconds() + currentToken.expires_in)
            const different = creation - new Date();
            //refresh token if less that 10 minutes                 
            if (different <= 10 * 60 * 1000)
                setTimeout(async () => {
                    refreshToken();
                    console.log('Refresh Token')
                }, 500);
        }

        operation.setContext({
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: currentToken ? `Bearer ${currentToken.access_token}` : '',
            },
            fetchOptions: {
                mode: 'cors'
            },
        });
    };

    const timeoutLink = new ApolloLinkTimeout(getTimeout());

    const requestLink = new ApolloLink((operation, forward) =>
        new Observable(observer => {
            let handle;
            Promise.resolve(operation)
                .then(oper => request(oper))
                .then(() => {
                    handle = forward(operation).subscribe({
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer),
                    });
                })
                .catch(observer.error.bind(observer));

            return () => {
                if (handle) handle.unsubscribe();
            };
        })
    );

    return new ApolloClient({
        link: ApolloLink.from([
            onError(async ({ graphQLErrors, networkError, operation, forward }) => {
                if (graphQLErrors) {
                    console.log('[graphQLErrors]', graphQLErrors)
                    // log error messages to an error reporting service here
                    log(
                        reportLevel.error,
                        'graphQLErrors',
                        {
                            graphQLErrors
                        }
                    );

                    for (let err of graphQLErrors) {
                        // handle errors differently based on its error code
                        switch (err.extensions.code) {
                            case 'UNAUTHENTICATED':
                                // old token has expired throwing AuthenticationError,
                                // one way to handle is to obtain a new token and 
                                // add it to the operation context
                                const headers = operation.getContext().headers
                                const currentToken = (localStorage.getItem(token))
                                operation.setContext({
                                    headers: {
                                        ...headers,
                                        Authorization: currentToken ? `Bearer ${currentToken.access_token}` : '',
                                    },
                                });
                                // Now, pass the modified operation to the next link
                                // in the chain. This effectively intercepts the old
                                // failed request, and retries it with a new token
                                return forward(operation);

                            // handle other errors
                            case 'ANOTHER_ERROR_CODE':
                            // ...
                        }
                    }
                }
                else if (networkError) {
                    console.log('[networkError]', networkError)
                    // log error messages to an error reporting service here
                    log(
                        reportLevel.error,
                        networkError.name,
                        {
                            statusCode: networkError.statusCode
                        }
                    );

                    if (networkError.statusCode === 401) {
                        storage.removeItem(token);
                        if (onUnauthorized)
                            onUnauthorized()
                    } else if (networkError.statusCode === 408) {
                        pubSub.publish(NETWORK_TIMEOUT, networkError);
                    }
                    else {
                        pubSub.publish(NETWORK_ERROR, networkError);
                    }
                }
            }),
            requestLink,
            withClientState({
                defaults: {
                    isConnected: true
                },
                resolvers: {
                    Mutation: {
                        updateNetworkStatus: (_, { isConnected }, { cache }) => {
                            cache.writeData({ data: { isConnected } });
                            return null;
                        }
                    }
                },
                cache
            }),
            timeoutLink,
            new HttpLink({
                uri: apiUrl
            })
        ]),
        cache
    });
}

export const apiClientMobile = ({ cache, onUnauthorized }) => {
    const apiUrl = settingConfiguration.get(BASE_URL) ? settingConfiguration.get(BASE_URL) + 'graphql' : '';
    if (apiUrl === '')
        throw "API URL UNDEFINED"

    initlizeEndpoint();

    const request = async (operation) => {
        let currentToken = await localStorage.getItemAsync(token)
        if (currentToken && currentToken.access_token) {
            const creation = await creationKeyAsync(token);
            creation.setSeconds(creation.getSeconds() + currentToken.expires_in)
            const different = creation - new Date();
            //refresh token if less that 10 minutes
            if (different <= 10 * 60 * 1000) {
                await refreshTokenAsync();
                currentToken = await localStorage.getItemAsync(token)
            }
        }
        
        operation.setContext({
            headers: {
                Authorization: currentToken ? `Bearer ${currentToken.access_token}` : '',
            },
        });
    };

    const timeoutLink = new ApolloLinkTimeout(getTimeout());

    const requestLink = new ApolloLink((operation, forward) =>
        new Observable(observer => {
            let handle;
            Promise.resolve(operation)
                .then(oper => request(oper))
                .then(() => {
                    handle = forward(operation).subscribe({
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer),
                    });
                })
                .catch(observer.error.bind(observer));

            return () => {
                if (handle) handle.unsubscribe();
            };
        })
    );

    return new ApolloClient({
        link: ApolloLink.from([
            onError(({ graphQLErrors, networkError, operation, forward }) => {
                if (graphQLErrors) {
                    console.log("graphQLErrors", graphQLErrors);
                    for (let err of graphQLErrors) {
                        switch (err.extensions.code) {
                            case 'UNAUTHENTICATED':
                                localStorage.getItemAsync(token).then(currentToken => {
                                    const headers = operation.getContext().headers
                                    operation.setContext({
                                        headers: {
                                            ...headers,
                                            Authorization: currentToken ? `Bearer ${currentToken.access_token}` : '',
                                        },
                                    });
                                    forward(operation)
                                })
                        }
                    }
                } else if (networkError) {
                    console.log("networkError", networkError);
                    if (networkError.statusCode === 401) {
                        storage.removeItem(token);
                        if (onUnauthorized) onUnauthorized();
                    }
                }
            }),
            requestLink,
            withClientState({
                defaults: {
                    isConnected: true
                },
                resolvers: {
                    Mutation: {
                        updateNetworkStatus: (_, { isConnected }, { cache }) => {
                            cache.writeData({ data: { isConnected } });
                            return null;
                        }
                    }
                },
                cache
            }),
            timeoutLink,
            new HttpLink({
                uri: apiUrl
            })
        ]),
        cache
    });
}

export * from './category';
export * from './events';
export * from './news';
export * from './regions';
export * from './images';
export * from './sponsors';
export * from './notifications';
export * from './reporting';
