import React from 'react';
import { IconArrowRight } from '../Icons/Icons';

export const SponsorCard = ({ sponsor, className, onClick, onNameClick }) => {
    if (!sponsor.entity) {
        return null;
    }
    return (
        <div
            onClick={() => {onClick(sponsor)}}
            className={`sponsor-card flex flex-1 justify-between items-center  ${!!className ? className : ''}`} >
            <div className="sponsor-card-logo flex flex-1 justify-center items-center mr-20 rounded shadow p-10">
                {!!sponsor.entity.logo?.entity?.thumbnail?.large?.url &&
                    <img src={sponsor.entity.logo?.entity?.thumbnail?.large?.url} alt={sponsor.entity.title} />
                }
            </div>

            <div className="flex flex-1 items-center justify-between"  >
                <h3 className={`text-sm text-grey-dark font-semibold ${onNameClick ? "pointer" : ""}`}>
                    {sponsor.entity.title}
                </h3>
                <div className="pointer ms-10">
                    <IconArrowRight />
                </div>
            </div>
        </div>
    );
};
