import { GetCompanyCountry, GetCompanyName } from '@fci/business-layer/services/user';
import QRCode from 'qrcode'
import vCardsJS from "vcards-js";

export const addLog = (log) => {
    console.log(JSON.stringify(log))
}

export const generateQRCodeFromVCard = async (user) => {
    return await new Promise((resolve, reject) => {
        const vCard = vCardsJS();
        if (user.fieldName.title)
            vCard.namePrefix = `${user.fieldName.title}`;

        if (user.fieldName.given)
            vCard.firstName = `${user.fieldName.given}`;

        if (user.fieldName.middle)
            vCard.middleName = `${user.fieldName.middle}`;

        if (user.fieldName.family)
            vCard.lastName = `${user.fieldName.family}`;

        if (user.mail)
            vCard.email = user.mail;

        if (user.phone)
            vCard.workPhone = user.phone;

        if (user.jobTitle)
            vCard.title = user.jobTitle;

        if (user.linkedin?.uri)
            vCard.url = user.linkedin?.uri;

        if (GetCompanyName(user))
            vCard.organization = GetCompanyName(user);

        if (GetCompanyCountry(user))
            vCard.workAddress.countryRegion = GetCompanyCountry(user);

        vCard.getFormattedString();

        const vCardString = vCard.getFormattedString();

        QRCode.toDataURL(vCardString, function (err, code) {
            if (err) {
                reject(reject)
                return;
            }
            resolve(code);
        })
    });
}

window.addLog = addLog;