import React, { useState, useEffect } from 'react';
import { IconArrow } from '..';
import { ProgramCard } from '..';

export const ProgramAccordionItem = ({ className, title, programs, defaultState, onSpeakerClick, eventPrograms }) => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(defaultState);
    }, [defaultState])
    return (
        <div className="accordion-item flex items-center text-sm ml-20 mr-20 leading">
            <div className="accordion-header flex items-center w-full justify-between pointer" onClick={() => {
                setShow(!show);
            }}>
                <h3 className="font-semibold text-md text-blue pb-20 pt-20">{title}</h3>
                <IconArrow className={`ms-10 icon-arrow text-blue-light ${show ? "show" : ""}`} />
            </div>

            <div className={`accordion-body ${show ? "show" : ""}`}>
                {programs.map((program, index) => {
                    return <ProgramCard key={index} program={program} onClick={onSpeakerClick} programs={eventPrograms} currentDay={title} />
                })}
            </div>
        </div>
    );
};
