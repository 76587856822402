import React, { createContext, useEffect, useRef, useState } from 'react';
import axios from "axios";
import Echo from 'laravel-echo';
import 'pusher-js';
import { GetCurrentUserDetail } from "@fci/business-layer/services/user";
import storage, { settingConfiguration } from "@fci/business-layer/storage";
import {
    REACT_APP_MIX_AUTH_LOCATION,
    REACT_APP_MIX_PUSHER_APP_KEY,
    REACT_APP_MIX_PUSHER_LOCATION,
    REACT_APP_URL,
    token
} from "@fci/business-layer/constants";
import { GetFullName } from '@fci/business-layer/services/user';
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { OpenRoom, FetchMessages, AddUserToRoom, RemoveUserFromRoom, CreateRoom, UpdateRoom, DeleteRoom, SendMessage, DeleteMessage, UpdateMessage, StartChatWith, MarkAllMessagesAsRead, FetchRooms, FetchActiveUsers } from '@fci/business-layer/services/chats';

/**
 * Socket app routes
 */
const reactAppUrl = settingConfiguration.get(REACT_APP_URL);
const reactAppMixPusherAppKey = settingConfiguration.get(REACT_APP_MIX_PUSHER_APP_KEY);
const reactAppMixPusherLocation = settingConfiguration.get(REACT_APP_MIX_PUSHER_LOCATION);
const reachAppMixAuthLocation = settingConfiguration.get(REACT_APP_MIX_AUTH_LOCATION);

const ChatContext = () => {
    /**
     * Default Prover and Consumer
     */
    const InnerChatContext = createContext(null);

    const getEcho = (currentToken) => {

        if (!currentToken) {
            return null;
        }

        /**
         * Echo instance
         */
        const echo = new Echo({
            broadcaster: 'pusher',
            key: reactAppMixPusherAppKey,
            wsHost: reactAppMixPusherLocation,
            wsPort: 6001,
            wssPort: 6001,
            disableStats: true,
            encrypted: true,
            authEndpoint: reachAppMixAuthLocation,
            enabledTransports: ['ws', 'wss'],
            auth: {
                headers: {
                    Authorization: "Bearer " + currentToken.access_token
                }
            },
        });

        /**
         * Append headers
         */
        axios.defaults.headers.common['Authorization'] = `Bearer ${currentToken.access_token}`;

        echo.connector.pusher.connection.bind('connected', function () {
            axios.defaults.headers.common['X-Socket-Id'] = echo.socketId();
        });

        return echo;
    };

    /**
     * Custom Provider
     */
    function InnerChatProvider(props) {

        /**
         * Auth token
         */
        const [currentToken, setCurrentToken] = useState(null);
        const [echo, setEcho] = useState(null);
        const [user, setUser] = useState(null);

        useEffect(() => {
            if (!storage.getItem(token)) {
                return;
            }

            setCurrentToken(storage.getItem(token));

            if (!user) {
                props.client.query({
                    query: GetCurrentUserDetail,
                }).then(n => {
                    if (n?.data?.user) {
                        setUser(n.data.user);
                    }
                });
            }

        }, [])

        useEffect(() => {
            if (!storage.getItem(token)) {
                return;
            }

            setCurrentToken(storage.getItem(token));

            if (!user) {
                props.client.query({
                    query: GetCurrentUserDetail,
                }).then(n => {
                    if (n?.data?.user) {
                        setUser(n.data.user);
                    }
                });
            }

        }, [props.location.pathname, props.client, user])

        const [activeUsers, setActiveUsers] = useState([]);
        const [usersInChat, setUsersInChat] = useState(null);

        const [rooms, setRooms] = useState([]);
        const [unreadMessagesByRoom, setUnreadMessagesByRoom] = useState({});
        const [activeRoom, setActiveRoom] = useState(null);
        const [usersSubscribed, setUsersSubscribed] = useState(null);

        const [messages, setMessages] = useState(null);

        const [usersTyping, setUsersTyping] = useState([]);

        const [loading, setLoading] = useState(true);

        const [chatWithRoomLoading, setChatWithRoomLoading] = useState(false);

        const [scrolledToBottom, setScrolledToBottom] = useState(true);

        const [isFetchingMessages, setIsFetchingMessages] = useState(false);
        const [noMoreMessagesToFetch, setNoMoreMessagesToFetch] = useState(false);

        const [isFetchingRooms, setIsFetchingRooms] = useState(false);
        const [noMoreRoomsToFetch, setNoMoreRoomsToFetch] = useState(false);

        const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);
        const [isAddingUsersToGroup, setIsAddingUsersToGroup] = useState(false)

        const stateRef = useRef(null);

        stateRef.current = {
            user,
            usersInChat,
            rooms,
            activeRoom,
            messages,
            usersSubscribed,
            usersTyping,
            loading,
            chatWithRoomLoading,
            scrolledToBottom,
            unreadMessagesByRoom,
            noMoreMessagesToFetch,
            isFetchingMessages,
            totalUnreadMessages,
            activeUsers,
            isFetchingRooms,
            noMoreRoomsToFetch,
            isAddingUsersToGroup
        };

        useEffect(() => {
            if (!!user && !!echo) {
                actions.fetchRooms(true);

                socketHelpers.joinUserChannel();

                helpers.fetchActiveUsers();
            }
        }, [user, echo]);

        /**
         * Custom logic
         */
        useEffect(() => {
            if (!!user && !!echo) {
                if (scrolledToBottom && activeRoom && !!unreadMessagesByRoom[activeRoom.id]) {
                    actions.markAllMessagesAsRead(activeRoom);
                }
            }
        }, [scrolledToBottom, activeRoom, user, echo]);

        useEffect(() => {
            if (!!user && !!echo) {
                let orderedRooms = helpers.orderRoomListByDateDesc(rooms);

                setRooms(orderedRooms);
            }
        }, [unreadMessagesByRoom, user, echo]);

        useEffect(() => {
            if (!!user && !echo) {
                setEcho(getEcho(currentToken));
            }
        }, [user, echo]);

        useEffect(() => {
            if (!!user && !!echo) {
                let totalUnread = 0;

                Object.keys(unreadMessagesByRoom).forEach(tu => {
                    totalUnread += unreadMessagesByRoom[tu];
                });

                setTotalUnreadMessages(totalUnread);
            }
        }, [unreadMessagesByRoom, user, echo]);

        if (!user && !echo) {
            return (<>{props.children}</>);
        }

        const actions = {
            /**
             * User
             */
            /**
             * TODO: if needed
             */

            /**
             * Room
             */
            openRoom: (room, isCreated = false, isFromApp = false) => {
                setLoading(true);

                OpenRoom(room.id)
                    .then(res => {
                        if (res) {
                            const roomData = res.data.data;

                            if (isCreated) {
                                roomData.is_created = true;
                            }

                            if (isFromApp) {
                                roomData.is_from_app = true;
                            }

                            setActiveRoom(roomData);
                            setUsersSubscribed(roomData.relationships.users);
                            setLoading(false);

                            actions.fetchMessages(roomData, true);
                        }
                    })
                    .catch(error => {
                        //console.log(error);
                    });
            },

            closeRoom: () => {
                setMessages(null);
                setUsersSubscribed(null);
                setScrolledToBottom(true);
                setNoMoreMessagesToFetch(false);
                setActiveRoom(null);
            },

            fetchMessages: (room, firstLoad = false, successCallback, errorCallback) => {

                if (!isFetchingMessages && !noMoreMessagesToFetch) {
                    // setLoading(true);
                    setIsFetchingMessages(true);

                    FetchMessages(room.relationships.messages.links.self, messages && messages.data ? messages.data.length : 0)
                        .then(res => {
                            if (res) {
                                helpers.handleNewMessages(res.data);

                                if (firstLoad) {
                                    actions.scrollToEnd();
                                }

                                // setLoading(false);
                                setIsFetchingMessages(false);

                                /**
                                 * limit from laravel
                                 */
                                if (!firstLoad && res.data.data.length < 15) {
                                    setNoMoreMessagesToFetch(true);
                                }

                                !!successCallback && successCallback(res);
                            }
                        })
                        .catch(error => {
                            !!errorCallback && errorCallback(error);
                        });
                }
            },

            addUserToRoom: (room, user_ids, successCallback, errorCallback) => {
                setIsAddingUsersToGroup(true)
                AddUserToRoom(room.id, user_ids)
                    .then(res => {
                        setIsAddingUsersToGroup(false)
                        setUsersSubscribed(res.data.data.relationships.users);

                        !!successCallback && successCallback(res);
                    })
                    .catch(error => {
                        setIsAddingUsersToGroup(false)
                        !!errorCallback && errorCallback(error);
                    });
            },

            removeUserFromRoom: (room, user_ids, successCallback, errorCallback) => {
                RemoveUserFromRoom(room.id, user_ids)
                    .then(res => {
                        setUsersSubscribed(res.data.data.relationships.users);

                        !!successCallback && successCallback(res);
                    })
                    .catch(error => {
                        !!errorCallback && errorCallback(error);
                    });
            },

            createRoom: (data, successCallback, errorCallback) => {
                CreateRoom(data)
                    .then(res => {
                        const room = res.data.data;
                        if (data.users && data.users.length > 0)
                            actions.addUserToRoom(room, data.users)
                        
                        helpers.handleNewRoomChanged({
                            room: room,
                            status: 'created'
                        });

                        !!successCallback && successCallback(res);
                    })
                    .catch(error => {
                        !!errorCallback && errorCallback(error);
                    });
            },

            updateRoom: (room, data, successCallback, errorCallback) => {
                UpdateRoom(room.id, data)
                    .then(res => {
                        helpers.handleNewRoomChanged({
                            room: res.data.data,
                            status: 'updated'
                        })

                        !!successCallback && successCallback(res);
                    })
                    .catch(error => {
                        !!errorCallback && errorCallback(error);
                    });
            },

            deletedRoom: (room, successCallback, errorCallback) => {
                DeleteRoom(room.id)
                    .then(res => {
                        helpers.handleNewRoomChanged({
                            room: {
                                id: room.id
                            },
                            status: 'deleted'
                        })

                        !!successCallback && successCallback(res);
                    })
                    .catch(error => {
                        !!errorCallback && errorCallback(error)
                    });
            },

            /**
             * Messages
             */
            onMessageTyping: (room, status) => {
                socketHelpers.whisperRoomChannel(
                    room,
                    'typing',
                    {
                        user,
                        status
                    }
                );
            },

            sendMessage: (room, data, successCallback, errorCallback) => {
                SendMessage(room.id, data)
                    .then(res => {
                        if (res) {
                            helpers.handleNewMessage({
                                message: res.data.data,
                                status: 'added'
                            });

                            !!successCallback && successCallback(res);
                        }
                    })
                    .catch(error => {
                        !!errorCallback && errorCallback(error);
                    })
            },

            deleteMessage: (message, successCallback, errorCallback) => {
                DeleteMessage(message.attributes.room_id, message.id)
                    .then(res => {
                        if (res) {
                            helpers.handleNewMessage({
                                message: {
                                    id: message.id,
                                    attributes: {
                                        room_id: message.attributes.room_id
                                    }
                                },
                                status: 'deleted'
                            })

                            !!successCallback && successCallback(res);
                        }
                    })
                    .catch(error => {
                        !!errorCallback && errorCallback(error);
                    })
            },

            updateMessage: (data, message, successCallback, errorCallback) => {
                UpdateMessage(message.attributes.room_id, message.id, data)
                    .then(res => {
                        if (res) {
                            helpers.handleNewMessage({
                                message: res.data.data,
                                status: 'updated'
                            });

                            !!successCallback && successCallback(res);
                        }
                    })
                    .catch(error => {
                        !!errorCallback && errorCallback(error);
                    })
            },

            startChatWith: (user_uid) => {
                setChatWithRoomLoading(true);

                StartChatWith(user_uid)
                    .then(res => {
                        if (res) {
                            let room = res.data.data;

                            if (!!rooms.filter(r => r.id === room.id).length) {
                                actions.openRoom(room, false, true);
                            } else {
                                helpers.handleNewRoomChanged({
                                    room,
                                    status: 'created',
                                    isFromApp: true
                                });
                            }

                            setChatWithRoomLoading(false);
                        }
                    })
                    .catch(error => {
                        setChatWithRoomLoading(false);
                    })
            },

            markAllMessagesAsRead: (room) => {
                MarkAllMessagesAsRead(room.id)
                    .then(res => {
                        if (res) {
                            helpers.handleNewUnreadMessagesByRoomChanged({
                                room_id: room.id,
                                unread_messages_count: 0,
                            })
                        }
                    })
                    .catch(error => {

                    })
            },

            scrollToEnd: () => {
                setTimeout(() => {
                    const elem = document.querySelector('#chat-messages-list');

                    elem && (elem.scrollTop = 100000)
                }, 0);
            },

            generateChatName: (room) => {
                const {
                    user,
                } = {...stateRef.current};

                if (room && room.attributes.direct_message) {
                    const userChatWith = room.relationships.users.filter(u => u.attributes.uid !== user.id)[0];

                    if (userChatWith) {
                        return GetFullName(userChatWith.attributes);
                    }
                }

                return room.attributes.name;
            },

            resetTotalUnreadMessages: () => {
                setTotalUnreadMessages(0);
            },

            fetchRooms: (firstLoad = false, successCallback, errorCallback) => {

                if (!isFetchingRooms && !noMoreRoomsToFetch) {
                    setIsFetchingRooms(true);

                    FetchRooms(rooms && !!rooms.length ? rooms.length : 0)
                        .then(res => {
                            if (res) {
                                helpers.handleNewRooms(res.data.data);

                                setIsFetchingRooms(false);

                                let unreadMessages = {};
                                res.data.data.map(r => {
                                    socketHelpers.joinRoomChannel(r);
                                    unreadMessages[r.id] = r.attributes.unread_messages_count;
                                });

                                setUnreadMessagesByRoom(unreadMessages);

                                helpers.fetchActiveUsers();

                                if (firstLoad) {
                                    setLoading(false);
                                }

                                /**
                                 * limit from laravel
                                 */
                                if (!firstLoad && res.data.data.length < 15) {
                                    setNoMoreRoomsToFetch(true);
                                }

                                !!successCallback && successCallback(res);
                            }
                        })
                        .catch(error => {
                            !!errorCallback && errorCallback(error);
                        });
                }
            },

            disconnectOnLogout: () => {
                socketHelpers.disconnect();
            },
        };

        const helpers = {
            handleNewRooms: newRooms => {
                /**
                 * Listen events dont have access to state so get it from ref
                 */
                const {
                    rooms,
                } = {...stateRef.current};

                if (rooms && !!rooms.length) {
                    setRooms([
                        ...rooms,
                        ...newRooms
                    ]);
                } else {
                    setRooms(newRooms);
                }
            },

            handleNewMessages: newMessages => {
                /**
                 * Listen events dont have access to state so get it from ref
                 */
                const {
                    messages,
                } = {...stateRef.current};

                if (messages && messages.data) {
                    setMessages({
                        ...messages,
                        data: [...newMessages.data, ...messages.data]
                    });
                } else {
                    setMessages(newMessages);
                }
            },

            handleNewMessage: newMessage => {
                /**
                 * Listen events dont have access to state so get it from ref
                 */
                const {
                    user,
                    activeRoom,
                    messages,
                    scrolledToBottom,
                } = {...stateRef.current};

                /**
                 * Only if it is the active room
                 */

                if (activeRoom && newMessage.message.attributes.room_id === activeRoom.id) {
                    helpers.handleNewRoomChanged({
                        room: {
                            ...activeRoom,
                            attributes: {
                                ...activeRoom.attributes, updated_at: newMessage.message.attributes.updated_at
                            }
                        },
                        status: 'updated'
                    });

                    /**
                     * New message added
                     */
                    if (newMessage.status === 'added') {
                        if (messages.data.some(message => parseInt(message.id) === parseInt(newMessage.message.id))) {
                            // Fix for double message on sent
                        } else {
                            setMessages({
                                    ...messages,
                                    data: [...messages.data, newMessage.message]
                                }
                            );
                        }

                        if (scrolledToBottom || newMessage.message.attributes.user.attributes.uid === user.id) {
                            actions.scrollToEnd();
                        }

                        if (scrolledToBottom && newMessage.message.attributes.user.attributes.uid !== user.id) {
                            actions.markAllMessagesAsRead(activeRoom);
                        }
                    }

                    /**
                     * Message deleted
                     */
                    if (newMessage.status === 'deleted') {
                        setMessages({
                            ...messages,
                            data: messages.data.filter(m => m.id !== newMessage.message.id)
                        });
                    }

                    /**
                     * Message updated
                     */
                    if (newMessage.status === 'updated') {
                        setMessages({
                            ...messages,
                            data: messages.data.map(m => m.id === newMessage.message.id ? newMessage.message : m)
                        });
                    }
                }

            },

            handleNewUsersInChat: newUsersInChat => {
                /**
                 * Listen events dont have access to state so get it from ref
                 */
                const {
                    usersInChat,
                } = {...stateRef.current};

                setUsersInChat({
                    ...usersInChat,
                    [newUsersInChat.room.id]: newUsersInChat.users
                })
            },

            handleNewUserInChat: newUserInChat => {
                /**
                 * Listen events dont have access to state so get it from ref
                 */
                const {
                    usersInChat,
                } = {...stateRef.current};

                /**
                 * User joined room channel
                 */
                if (newUserInChat.status === 'joining') {
                    setUsersInChat({
                        ...usersInChat,
                        [newUserInChat.room.id]: [
                            ...usersInChat[newUserInChat.room.id],
                            newUserInChat.data
                        ]
                    });
                }

                /**
                 * User left room channel
                 */
                if (newUserInChat.status === 'leaving') {
                    setUsersInChat({
                        ...usersInChat,
                        [newUserInChat.room.id]: usersInChat[newUserInChat.room.id].filter(ou => ou.id !== newUserInChat.data.id)
                    });
                }
            },

            handleNewTyping: newTyping => {
                /**
                 * Listen events dont have access to state so get it from ref
                 */
                const {
                    user,
                    activeRoom,
                    usersTyping,
                } = {...stateRef.current};

                /**
                 * Only if it is the active room
                 */
                if (activeRoom && newTyping.room.id === activeRoom.id && newTyping.user.id !== user.id) {
                    if (newTyping.status === 'started') {
                        setUsersTyping([
                            ...usersTyping,
                            newTyping.user
                        ]);
                    }
                    if (newTyping.status === 'ended') {
                        setUsersTyping(usersTyping.filter(ut => ut.name !== newTyping.user.name));
                    }
                }
            },

            handleNewRoomSubscriptionChanged: newRoomSubscriptionChanged => {
                /**
                 * Listen events dont have access to state so get it from ref
                 */
                const {
                    rooms,
                    activeRoom,
                } = {...stateRef.current};

                /**
                 * User added to room
                 */
                if (newRoomSubscriptionChanged.status === 'added') {
                    let roomsUpdatedMap = [
                        ...rooms,
                        newRoomSubscriptionChanged.room
                    ];

                    let orderedRooms = helpers.orderRoomListByDateDesc(roomsUpdatedMap);

                    setRooms(orderedRooms);

                    socketHelpers.joinRoomChannel(newRoomSubscriptionChanged.room);
                }

                /**
                 * User deleted from room
                 */
                if (newRoomSubscriptionChanged.status === 'removed') {
                    setRooms(rooms.filter(r => r.id !== newRoomSubscriptionChanged.room.id));

                    if (activeRoom && activeRoom.id === newRoomSubscriptionChanged.room.id) {
                        actions.closeRoom();
                    }

                    socketHelpers.leaveRoomChannel(newRoomSubscriptionChanged.room);
                }
            },

            handleNewRoomChanged: newRoomChanged => {
                /**
                 * Listen events dont have access to state so get it from ref
                 */
                const {
                    rooms,
                    activeRoom,
                } = {...stateRef.current};

                /**
                 * Room is created
                 */
                if (newRoomChanged.status === 'created') {
                    let newRooms = [
                        ...rooms,
                        newRoomChanged.room
                    ];

                    let orderedRooms = helpers.orderRoomListByDateDesc(newRooms);

                    setRooms(orderedRooms);

                    socketHelpers.joinRoomChannel(newRoomChanged.room);

                    actions.openRoom(newRoomChanged.room, true, newRoomChanged.isFromApp);
                }

                /**
                 * Room is updated
                 */
                if (newRoomChanged.status === 'updated') {
                    let roomsUpdatedMap = rooms.map(r => r.id === newRoomChanged.room.id ? newRoomChanged.room : r);
                    let orderedRooms = helpers.orderRoomListByDateDesc(roomsUpdatedMap);

                    setRooms(orderedRooms);

                    if (activeRoom && activeRoom.id === newRoomChanged.room.id) {
                        setActiveRoom(newRoomChanged.room);
                    }
                }

                /**
                 * Room is deleted
                 */
                if (newRoomChanged.status === 'deleted') {
                    setRooms(rooms.filter(r => r.id !== newRoomChanged.room.id));
                    if (activeRoom && activeRoom.id === newRoomChanged.room.id) {
                        actions.closeRoom();
                        socketHelpers.leaveRoomChannel(newRoomChanged.room);
                    }
                }

                helpers.fetchActiveUsers();
            },

            handleNewUnreadMessagesByRoomChanged: newUnreadMessagesByRoomChanged => {
                /**
                 * Listen events dont have access to state so get it from ref
                 */
                const {
                    activeRoom,
                    scrolledToBottom,
                    unreadMessagesByRoom,
                    rooms,
                } = {...stateRef.current};

                if (!activeRoom
                    || (activeRoom && activeRoom.id !== newUnreadMessagesByRoomChanged.room_id)
                    || (activeRoom && activeRoom.id === newUnreadMessagesByRoomChanged.room_id && !scrolledToBottom)
                    || (activeRoom && activeRoom.id === newUnreadMessagesByRoomChanged.room_id && scrolledToBottom && newUnreadMessagesByRoomChanged.unread_messages_count === 0)
                ) {
                    setUnreadMessagesByRoom({
                        ...unreadMessagesByRoom,
                        [newUnreadMessagesByRoomChanged.room_id]: newUnreadMessagesByRoomChanged.unread_messages_count
                    });

                    let roomUpdate = rooms.filter(r=> r.id === newUnreadMessagesByRoomChanged.room_id);

                    if (!!roomUpdate.length) {
                        roomUpdate[0].attributes.updated_at = newUnreadMessagesByRoomChanged.updated_at;

                        helpers.handleNewRoomChanged({
                            room: roomUpdate,
                            status: 'updated'
                        });
                    }
                }
            },

            orderRoomListByDateDesc: (rooms) => {
                if (!!rooms.length) {
                    return rooms.slice().sort(function (a, b) {
                        return a.attributes.updated_at > b.attributes.updated_at ? -1 : 1;
                    });
                }

                return rooms;
            },

            fetchActiveUsers: () => {
                FetchActiveUsers()
                    .then(res => {
                        if (res) {
                            setActiveUsers(res.data);
                        }
                    })
                    .catch(error => {

                    });
            },
        }

        const socketHelpers = {
            joinUserChannel: () => {
                echo.join(`users.${user.id}`)
                    .listen('.added.to.room', (e) => {
                        const {room} = e;

                        helpers.handleNewRoomSubscriptionChanged({
                            room,
                            status: 'added'
                        });
                    })
                    .listen('.removed.from.room', (e) => {
                        const {room} = e;

                        helpers.handleNewRoomSubscriptionChanged({
                            room,
                            status: 'removed'
                        });
                    })
                    .listen('.room.unread.messages.changed', (e) => {
                        helpers.handleNewUnreadMessagesByRoomChanged(e);
                    });
            },

            joinRoomChannel: (room) => {
                echo.join(`rooms.${room.id}`)
                    .here((users) => {
                        helpers.handleNewUsersInChat({
                            room,
                            users
                        });
                    })
                    .joining((user) => {
                        helpers.handleNewUserInChat({
                            data: user,
                            room,
                            status: 'joining'
                        });
                    })
                    .leaving((user) => {
                        helpers.handleNewUserInChat({
                            data: user,
                            room,
                            status: 'leaving'
                        });
                    })
                    .listen('.room.updated', (e) => {
                        const {room} = e;

                        helpers.handleNewRoomChanged({
                            room,
                            status: 'updated'
                        });
                    })
                    .listen('.room.deleted', (e) => {
                        helpers.handleNewRoomChanged({
                            room: {
                                id: room.id
                            },
                            status: 'deleted'
                        });
                    })
                    .listen('.message.sent', (e) => {
                        const {message} = e;

                        helpers.handleNewMessage({
                            message,
                            status: 'added'
                        });
                    })
                    .listen('.message.deleted', (e) => {
                        const {message_id} = e;

                        helpers.handleNewMessage({
                            message: {
                                id: message_id,
                                attributes: {
                                    room_id: room.id
                                }
                            },
                            status: 'deleted'
                        });
                    })
                    .listen('.message.updated', (e) => {
                        const {message} = e;

                        helpers.handleNewMessage({
                            message,
                            status: 'updated'
                        });
                    })
                    .listen('.subscribers.updated', (e) => {
                        const {room} = e;

                        setUsersSubscribed(room.relationships.users);
                    })
                    .listenForWhisper('typing', (e) => {
                        helpers.handleNewTyping({
                            ...e,
                            room
                        });
                    });
            },

            whisperRoomChannel: (room, event, data) => {
                echo.join(`rooms.${room.id}`)
                    .whisper(event, data);
            },

            leaveRoomChannel: (room) => {
                echo.leave(`rooms.${room.id}`);
            },

            disconnect: () => {
                echo.leave();
                echo.disconnect();
            },
        }

        const {children} = props;

        return (
            <InnerChatContext.Provider value={{
                state: {
                    ...stateRef.current,
                    setters: {
                        setScrolledToBottom
                    },
                },
                actions: actions,
            }}>
                {children}
            </InnerChatContext.Provider>
        );
    }

    // const ChatConsumer = Consumer;
    const ChatProvider = withRouter(withApollo(InnerChatProvider));

    return {InnerChatContext, ChatProvider};
};

export default ChatContext();
