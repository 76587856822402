import React, { useState } from 'react';
import moment from "moment";
import { NavLink } from "react-router-dom";
import { Trans } from 'react-i18next';
import { convertDateToMoment } from '@fci/business-layer/helpers/date-helpers';
import usePersistantState from '../../hooks/usePersistantState';

export const EventCard = ({ entity, user }) => {
    const [backgroundImage, setBackgroundImage] = useState('/assets/images/no-pict.png')
    const [lastActivePage, setLastActivePage] = usePersistantState('my_event_active_page', '');
    let date = convertDateToMoment(entity.startDateTz);
    let endDate = convertDateToMoment(entity.endDateTz);

    const isUserRegistered = entity?.attendees && entity?.attendees?.users?.length > 0 && entity?.attendees?.users?.filter(x => x.id == user.id).length > 0

    useState(() => {
        setBackgroundImage(entity.backgroundImage.entity.thumbnail.medium.url);
    }, [entity]);

    return (
        <article className="flex flex-col pt-20 pb-20">
            <NavLink to={isUserRegistered ? `/my-events/${entity.id}` : `/events/${entity.id}`} className="flex flex-col flex-1" exact={true} onClick={() => setLastActivePage('')}>
                <div className="bg-cover w-full h-160 mb-30" style={{ backgroundImage: `url(${backgroundImage})` }} />

                <div className="flex justify-between items-center mb-10">
                    <h3 className="text-sm text-orange font-bold uppercase">{entity.category?.entity?.label}</h3>

                    <h4 className="text-sm font-semibold text-grey-light">{entity.accessType?.entity?.entityLabel}</h4>
                </div>

                <div>
                    <h2 className="text-md text-blue font-semibold mb-5">{entity.title}</h2>

                    <h4 className="text-sm font-semibold text-grey-light text-elipsis">

                        {date.isSame(endDate, 'day') ?
                            <>
                                <time className="text-xs"
                                    dateTime={date.format()}>
                                    {date.format('DD MMMM YYYY')}
                                </time>
                            </>
                            :
                            <>
                                <time className="text-xs"
                                    dateTime={date.format()}>
                                    {date.format('DD MMMM YYYY')}
                                </time> <Trans i18nKey="to">to</Trans> <time className="text-xs"
                                    dateTime={endDate.format()}>
                                    {endDate.format('DD MMMM YYYY')}
                                </time>
                            </>
                        }
                        , {entity.city}, {entity.venue}</h4>
                </div>
            </NavLink>
        </article>
    );
};