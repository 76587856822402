import Axios from '../helpers/axios-helpers';
import { BASE_URL } from '../constants'
import { settingConfiguration } from '../storage'

export const reportLevel = {
  debug: 'debug',
  info: 'info',
  warning: 'warning',
  error: 'error',
  critical: 'critical',
  emergency: 'emergency'
}

export const log = async (level, message, context = {}) => {
  console.log('[LOG]', level, context)
  const apiUrl = settingConfiguration.get(BASE_URL) ? settingConfiguration.get(BASE_URL) + 'api/logs' : '';
  await Axios.post(apiUrl, JSON.stringify({
    level,
    message,
    context
  }));
}