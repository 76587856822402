import moment from 'moment-timezone'

export const buildTimeEvent = (start, end) => {
    const startFormated = start.format('dddd DD MMM YYYY');
    const endFormated = end.format('dddd DD MMM YYYY');
    if (startFormated === endFormated) {
        return `${start.format("HH:mm")} - ${end.format("HH:mm")}`
    }
    return `${start.format("HH:mm")} - ${end.format("dddd DD MMM YYYY HH:mm")}`
}

export const convertDateTimeToLocal = (datetime) => {
    let localTZ = moment.tz.guess();
    return datetime ? moment.tz(datetime, "Europe/Brussels").tz(localTZ) : moment()
}

export const generateGmtText = (start) => {
    const localGMT = start.utcOffset() / 60;
    return "(GMT" + (localGMT >= 0 ? "+" + localGMT : localGMT) + ")"
}

export const convertDateFromTimezoneToLocal = (dateTime, timezoneFrom) => {
    const localTZ = moment.tz.guess();
    const fromDate = moment.tz(dateTime, timezoneFrom);
    const toDate = fromDate.clone().tz(localTZ);
    // console.log(dateTime, timezoneFrom, localTZ)
    // console.log(fromDate.format('dddd D MMMM HH:mm'), toDate.format('dddd D MMMM HH:mm'))
    return toDate;
}

export const convertDateToMoment = (datetimeWithTz) => {
    return moment.parseZone(datetimeWithTz)
}

export const convertMomentDateToLocal = (datetime) => {
    // let localTZ = moment.tz.guess();
    return datetime.clone().local();
}