import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { en } from '@fci/business-layer/assets/i18n/translations';

    const resources = {
        en: {
            translation: en
        },
    };

    i18n.use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources,
            lng: "en",
            fallbackLng: 'en',
            debug: process.env.NODE_ENV !== "production",
            keySeparator: false, // we do not use keys in form messages.welcome
            interpolation: {
                escapeValue: false // react already safes from xss
            }
    });

export default i18n;
