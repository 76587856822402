import React, { useEffect, useState } from 'react';
import useIntersect from '../../hooks/useIntersect';
import { Modal } from './Modal';
import { IconTwitter, IconFacebook, IconLinkedin, IconDownload } from '../Icons/Icons';
import { BASE_URL } from '@fci/business-layer/constants';
import { settingConfiguration } from '@fci/business-layer/storage';
const buildThresholdArray = () => Array.from(Array(100).keys(), i => i / 100);
export const LinkBoxMyEventPicture = ({ item }) => {
    const socialClass = 'flex flex-1 items-center justify-center b-social text-white rounded pt-15 pb-15';
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [ref, entry] = useIntersect({
        threshold: buildThresholdArray()
    });
    useEffect(() => {
        if (entry.intersectionRatio === 1 && loaded === false)
            setLoaded(true)
    }, [entry, loaded]);

    const renderImage = () => {
        if (entry.intersectionRatio === 1 || loaded === true) {

            return <div className="bg-cover w-full h-full" style={{ backgroundImage: `url(${item.thumbnail.medium.url})` }} />
        }
        return <div className="bg-cover w-full h-full" />
    }
    const onClick = () => {
        if (showModal === false)
            setShowModal(!showModal)
    }
    const shareLinkedIn = () => {
        window.open(`https://www.linkedin.com/shareArticle/?mini=true&url=${item.thumbnail.original.url}`, "_blank");
    }
    const shareOnFacebook = () => {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${item.thumbnail.original.url}`, "_blank");
    }
    const shareOnTwitter = () => {
        window.open(`https://twitter.com/intent/tweet?text=${item.thumbnail.original.url}`, "_blank");
    }
    const downloadPicture = () => {
        window.open(`${settingConfiguration.get(BASE_URL)}media/${item.entityId}/download`, '_self');
    }
    return (
        <div ref={ref}
            className="square-box rect-variant flex shadow bg-grey" onClick={onClick}>
            <Modal show={showModal} title={item.title} onClose={() => {
                setShowModal(false)
            }} extraButtons={[
                <span className={`${socialClass} ms-10 me-10`}
                    onClick={shareLinkedIn}>
                    <IconLinkedin className="text-violet" />
                </span>
                ,
                <span className={`${socialClass} ms-10 me-10`}
                    onClick={shareOnFacebook}>
                    <IconFacebook className="text-violet" />
                </span>
                ,
                <span className={`${socialClass} ms-10 me-10`}
                    onClick={shareOnTwitter}>
                    <IconTwitter className="text-violet" />
                </span>
                ,
                <span className={`${socialClass} ms-10 me-10`}
                    onClick={downloadPicture}>
                    <IconDownload className="text-violet" />
                </span>
            ]}>
                <div className="bg-cover" >
                    <img src={item.thumbnail.original.url} alt={item.thumbnail.alt} />
                </div>
            </Modal>
            <div className="inner p-5 flex flex-col justify-center items-center">
                {renderImage()}
            </div>
        </div>
    );
};
