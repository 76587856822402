import { gql } from 'apollo-boost';

export const MediaImageFragment = gql`
fragment MediaImageFragment on MediaImage {
  thumbnail {
    title
    alt
    medium:derivative(style: MEDIUM) {
      url
      width
      height
    }
    large:derivative(style: LARGE) {
      url
      width
      height
    }
  }
  fieldMediaImage {
    url
    entity {
      url
      filename
    }
  }
}
`;

export const treasureImageType = {
  news: 'news_list',
  event: 'events_list',
  course: 'course',
  library: 'document_library',
  member: 'global_search'
}

export const GetTreasureImages = gql`
query GetTreasureImages($type:[String]){
  configPagesQuery(
    filter: {
      conditions: {
        field: "type"
        value: $type
      }
    }
  ){
    entities{
      ... on ConfigPagesNewsList {
        fieldHeroImage {
          entity {
            ...MediaImageFragment
          }
        }
      }
      ...on ConfigPagesEventsList{
        fieldHeroImage {
          entity{
            ...MediaImageFragment
          }
        }
      }
      ...on ConfigPagesMembers {
        fieldHeroImage {
          entity{
            ...MediaImageFragment
          }
        }
      }
      ...on ConfigPagesCourse {
        fieldHeroImage {
          entity{
            ...MediaImageFragment
          }
        }
      }
      ... on ConfigPagesDocumentLibrary {
        fieldHeroImage {
          entity {
            ...MediaImageFragment
          }
        }
        introduction: fieldIntroduction {
          processed
        }
      }
      ... on ConfigPagesGlobalSearch {
        fieldHeroImage {
          entity{
            ...MediaImageFragment
          }
        }
      }
    }
  }
}

${MediaImageFragment}
`;


export const GetEventPictures = gql`
query ($limit: Int, $offset: Int,$id: String!) {
  result: nodeById(id: $id) {
    ...EventFragment
    __typename
  }
}

fragment EventFragment on NodeEvent {
  id: nid
  title
  pictures: queryFieldPictures(offset: $offset, limit: $limit) {
    count
    entities {
      entityId
      ...MediaImageFragment
      __typename
    }
    __typename
  }
  __typename
}

fragment MediaImageFragment on MediaImage {
  id: mid  
  title:entityLabel
  thumbnail {
    title
    alt
    original: entity{
          url
   }
    medium: derivative(style: MEDIUM) {
      url
      width
      height
      __typename
    }
    large: derivative(style: LARGE) {
      url
      width
      height
      __typename
    }
    __typename
  }
  fieldMediaImage {
    url
    __typename
  }
  __typename
}
`