import React from 'react';
import Select from "react-select";
import { Trans, useTranslation } from "react-i18next";
import { FieldError } from "./FieldError";

export const ReactSelect = ({
    className,
    name,
    placeholder,
    onChange,
    options,
    isSearchable = false,
    isMulti = false,
    selectComponents,
    errors,
    hasErrors,
    value,
    noOptionsMessage
}) => {
    const { t } = useTranslation();

    return (
        <div className={`select ${className ? className : ''}`}>
            <Select
                { ...( !!value && { value: value || '' } ) }
                noOptionsMessage={() => !!noOptionsMessage ? noOptionsMessage : t('noOptions')}
                onChange={onChange}
                options={options}
                name={name}
                classNamePrefix="custom-react-select"
                placeholder={!!placeholder ? placeholder : <Trans i18nKey="pleaseSelect">Select...</Trans>}
                isSearchable={isSearchable}
                isMulti={isMulti}
                components={selectComponents}
            />

            <FieldError name={name} hasErrors={hasErrors} errors={errors} />
        </div>
    );
};
