import React from 'react';
import { Trans } from "react-i18next";
import { Button } from '../Form/Button';

export const Snackbar = () => {

    const handleOnClick = () => {
        document.getElementById('snackbar').className = '';
        window.localStorage.setItem("version", document.getElementById('version').innerText);
        window.location.reload();
    };

    return (
        <>
            <div id="snackbar">
                <div className="text-lg">
                    <Trans i18nKey="UpdateInformations">We have new version</Trans>  <span id="version"></span>
                </div>
                
                <Button
                    onClick={handleOnClick}>
                    {<Trans i18nKey="UpdateNewVersion">
                        Update new version
                    </Trans>}
                </Button>
            </div>
        </>
    );
};
