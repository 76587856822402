import React from 'react';

export const Avatar = ({url, size, className}) => {
    if (!url) {
        return null;
    }

    let sizeClass;

    switch (size) {
        case 'sm':
            sizeClass = ' avatar-sm';
            break;
        case 'md':
            sizeClass = ' avatar-md';
            break;
        case 'lg':
            sizeClass = ' avatar-lg';
            break;
        default:
            sizeClass = '';
    }

    return (
        <div className={`avatar${sizeClass} ${className ? className : ''}`} style={{ backgroundImage: `url(${url})`}}/>
    );
};
