import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Avatar } from '../GlobalElements/Avatar';
import { GetFullName, GetCompanyName, GetCompanyCountry } from '@fci/business-layer/services/user';
import { IconMiniChat } from '../Icons/Icons';
import { useTranslation } from 'react-i18next';

export const AttendeeCard = ({ entity, canChat, startChatWith }) => {
    const { t } = useTranslation();
    const [image, setImage] = useState('/assets/images/no-pict.png');

    useEffect(() => {
        if (entity?.userPicture?.derivative?.url)
            setImage(entity?.userPicture?.derivative?.url);
    }, [entity])

    // console.log("GetCompanyName(entity)", GetCompanyName(entity));
    const companyName = GetCompanyName(entity);
    const companyCountry = GetCompanyCountry(entity);
    const fullName = GetFullName(entity);

    return (
        <article className="attendee-card flex pt-20 pb-20 b-b-white-dark">
            <div className="avatar-wrapper">
                <Avatar url={image} size="md" />
            </div>
            <div className="flex flex-col justify-center flex-1 ms-5 me-60">
                { fullName && (<h2 className="text-blue font-bold text-base">{fullName}</h2>) }
                { entity?.jobTitle && (<h3 className="text-base-color text-sm font-semibold">{entity?.jobTitle} </h3>) }
                { companyName && (<h3 className="text-base-color text-sm">{companyName}</h3>)}
                { companyCountry && (<h3 className="text-blue-light text-sm">{companyCountry}</h3>)}
            </div>

            {!!canChat &&
                <Link to={"/chat"}
                    onClick={() => startChatWith(entity.id, 'attendees')}>
                    <div className="attendee-icon">
                        <IconMiniChat/>
                        <h5 className="text-grey-light text-sm text-center">
                            {t("chat")}
                        </h5>
                    </div>
                </Link>
            }
        </article>



    );
};
