import React from 'react';
import { NavLink } from "react-router-dom";
import { IconArrowLong } from "..";

export const BackButtonTitle = ({to, title}) => {
    if (!to && !title) {
        return null;
    }

    return (
        <div className="flex items-center h-56 font-bold text-sm pl-20 pr-20">
            <NavLink to={to} className="flex items-center leading" exact={true}>
                <IconArrowLong className="text-violet text-xs mr-15"/>
                <span className="text-blue text-3xl">{title}</span>
            </NavLink>
        </div>
    );
};
