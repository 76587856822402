import { gql } from 'apollo-boost';

export const MediaDocumentFragment = gql`
fragment MediaDocumentFragment on MediaDocument {
  document: fieldMediaDocument {
    entity {
      url
      filename
      filesize
      filemime
      origname
    }
  }
}

`;

export const MediaEventDocumentFragment = gql`
fragment MediaEventDocumentFragment on MediaEventDocument {
  document: fieldMediaFile {
    entity {
      url
      filename
      filesize
      filemime
      origname
    }
  }
}

`;

export const MediaPrivateDocumentFragment = gql`
fragment MediaPrivateDocumentFragment on MediaPrivateDocument {
  id: entityId
  document: fieldMediaFile {
    entity {
      url
      filename
      filesize
      filemime
      origname
      created
    }
  }
  directory{
    entity{
      id: tid
      name
      parent {
        entity{
          id: tid
          name
        }
      }
    }
  }
}

`;