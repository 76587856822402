import React from 'react';
import { Trans } from 'react-i18next';
import { IconError } from '../Icons/Icons';

const ErrorContent = (props) => {
    return <div className="flex items-center p-20 justify-between">
        <IconError className='icon-lg' />
        <h2 className="text-lg font-semibold text-center ml-10">
            <Trans i18nKey="errroInformation">
                We have something wrong, please contact administrtor
        </Trans>
        </h2>
        <h2 className="text-lg font-semibold text-center text-blue-light pointer" onClick={() => {
            window.location.reload();
        }}>
            <Trans i18nKey="clickHereToTryAgain">
                Click here to try again
        </Trans>
        </h2>
    </div>
}
export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        console.log(error)
    }
    render() {
        if (this.state.hasError) {
            return <ErrorContent />
        }

        return this.props.children;
    }
}
export const Error = (props) => {
    if (props.online === false)
        return <div className="flex items-center p-20">
            <IconError className='icon-lg' />
            <h2 className="text-lg font-semibold text-center ml-10">
                <Trans i18nKey="offlineMessage">
                    You're Offline
                </Trans>
                <h2 className="text-lg font-semibold text-center text-blue-light pointer" onClick={() => {
                    window.location.reload();
                }}>
                    <Trans i18nKey="clickHereToTryAgain">
                        Click here to try again
                    </Trans>
                </h2>
            </h2>
        </div>
    return <ErrorContent />

}