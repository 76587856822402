import React from 'react';
import { Trans } from "react-i18next";

export const Offline = props => {
    return (
        <div className="flex items-center justify-center">
            <h2 className="text-xl text-blue font-semibold">
                <Trans i18nKey="youAreOffline">You are offline</Trans>
            </h2>
        </div>
    );
};
