import React from 'react';
import { FieldError } from "./FieldError";

export const Input = ({
    name,
    value,
    label,
    placeholder,
    type,
    onChange,
    readOnly,
    className,
    hasErrors,
    icon,
    errors,
    checked,
    variant, attributes
}) => {
    let variantClass = 'text-base';

    if (!!variant && variant === 'shadow') {
        variantClass = 'shadow b-none text-base';
    }

    return (
        <div className={`input-field ${className}`}>
            {label &&
                <label htmlFor={name}>{label}</label>
            }

            <input
                className={`h-50 rounded pl-20 pr-40 w-full text-base-color ${variantClass}`}
                id={name}
                type={type}
                value={value}                
                name={name}
                placeholder={placeholder}
                readOnly={readOnly}
                checked={checked}
                onChange={onChange}
                {...attributes}
            />
            {icon && <div className={`icon-wrapper mr-10`}>
                {icon}
            </div>}

            <FieldError name={name} hasErrors={hasErrors} errors={errors} />
        </div>
    );
};
