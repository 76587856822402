import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { apiClient } from '@fci/business-layer/services';
import Routes from "./routes";
import { ApolloProvider } from 'react-apollo';
import { persistCache } from 'apollo-cache-persist';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import storage from '@fci/business-layer/storage';
import './sass/app.scss';
import { Footer, Loader, Snackbar } from "./components";
import Header from "./components/Shared/Header";
import { Helmet } from "react-helmet";
import fragmentTypes from '@fci/business-layer/services/fragmentTypes';
import networkHelpers from '@fci/business-layer/helpers/network-helpers';
import AppContext from "./AppContext"
import pubSub from '@fci/business-layer/helpers/pub-sub';
import { UNAUTHORIZE } from '@fci/business-layer/constants';
import ChatContext from "./Pages/Chat/ChatContext";
import ErrorBoundary from "./components/GlobalElements/ErrorBoundary";
import { InstallPrompter } from './components/GlobalElements/InstallPrompter';

const { MenuProvider } = AppContext;
const { ChatProvider } = ChatContext;

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: fragmentTypes
});

storage.init(window.localStorage, window.Configuration);

const cache = new InMemoryCache({ fragmentMatcher });

function App(props) {
    let [language,] = useState("");

    const onUnauthorized = () => {
        window.location.reload();
    };

    const initialize = async () => {
        await persistCache({
            cache,
            storage: window.localStorage,
        });
    };
    const onUnauthorize = () => {
        window.location = "/login";
    }
    useEffect(() => {
        const unsub = pubSub.subscribe(UNAUTHORIZE, onUnauthorize)
        return () => {
            unsub.remove();
        }
    }, [])
    useEffect(() => {
        initialize();

        // Detect device orientation
        onOrientationChange();

        if ((window.screen.orientation) && ('onchange' in window.screen.orientation)) {
            window.screen.orientation.addEventListener('change', onOrientationChange)
        } else if ('onorientationchange' in window) {
            window.addEventListener('orientationchange', onOrientationChange)
        } else {
            console.warn('No orientationchange events')
        }

        window.addEventListener("resize", onOrientationChange);
        window.addEventListener('online', networkIndicator);
        window.addEventListener('offline', networkIndicator);
    }, []);
    const networkIndicator = () => {
        if (!navigator.onLine) {
            networkHelpers.setOffline();
            console.log('Your are Offline');
        }
        else {
            networkHelpers.setOnline();
            console.log('Your are Online');
            window.location.reload();
        }
    }

    const onOrientationChange = () => {
        let orientation = 'portrait';
        let type = 'primary';
        let angle = 0;

        if (window.orientation) {
            angle = window.orientation;
            orientation = Math.abs(angle) === 90 ? 'landscape' : 'portrait';
        }

        if (window.screen.orientation) {
            [orientation, type] = window.screen.orientation.type.split('-');
            angle = window.screen.orientation;
        }

        if (orientation === 'portrait') {
            document.documentElement.classList.add('portrait');
            document.documentElement.classList.remove('landscape');
        } else {
            document.documentElement.classList.add('landscape');
            document.documentElement.classList.remove('portrait');
        }
    };

    // Disable console logs in production
    if (process.env.NODE_ENV === "production") {
        console.log = function no_console() {};
    }

    return (
        <BrowserRouter baseUrl={"/"} >
            <ApolloProvider client={apiClient({
                cache: cache, onUnauthorized
            })}>
                <ErrorBoundary>
                    <ChatProvider>
                        <MenuProvider>
                            <Helmet>
                                <body className={language} />
                            </Helmet>

                            <Header {...props} />

                            <React.Suspense fallback={<Loader />}>
                                <Routes />
                            </React.Suspense>

                            <Footer {...props} />

                            <Snackbar />
                            
                            <InstallPrompter />
                        </MenuProvider>
                    </ChatProvider>
                </ErrorBoundary>
            </ApolloProvider>
        </BrowserRouter >
    );
}

export default App;
