import React, {createContext, useState} from 'react';

const AppContext = () => {
    const MenuContext = createContext(false);

    const MenuProvider = (props) => {
        const [showMenu, setShowMenu] = useState(false);

        const toggleMenu = () => {
            setShowMenu(!showMenu)

            if (showMenu) {
                document.body.classList.add('menu-open');
            } else {
                document.body.classList.remove('menu-open');
            }
        };

        const openMenu = () => {
            setShowMenu(true)
            document.body.classList.add('menu-open');
        };

        const closeMenu = () => {
            setShowMenu(false)
            document.body.classList.remove('menu-open');
        };

        const MenuState = {
            showMenu,
            toggleMenu,
            openMenu,
            closeMenu
        };

        return(
            <MenuContext.Provider value={MenuState}>
                { props.children }
            </MenuContext.Provider>
        )
    }

    return {
        MenuContext,
        MenuProvider
    };
};

export default AppContext()
