
import React from 'react';
import { Trans } from 'react-i18next';
export const ListBoxAppSponsor = ({ appSponsor }) => {

    return <div className="h-72 rect-variant flex rounded shadow bg-white">
        <div className="inner flex justify-center items-center w-full justify-between flex-row ">
            <h3 className="text-sm text-blue font-semibold text-center ms-20"><Trans i18nKey="appSponsoredBy">App sponsored by :</Trans></h3>
            <div className="mr-10 flex flex-col justify-center items-center">
                <img className="w-auto h-40 mb-5" src={appSponsor?.entity?.logo?.entity?.thumbnail?.medium?.url} alt={appSponsor?.entity?.logo?.entity?.thumbnail?.alt} />
                <h2 className="text-xs font-semibold text-center">{appSponsor?.entity?.title}</h2>
            </div>
        </div>
    </div>

}
