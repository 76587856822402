import { gql } from 'apollo-boost';

export const GetSponsorDetail = gql`
  query($id: String!) {
    result: nodeById(id:$id)
  {
    ... on NodeSponsor{
      body{
        processed
      }
    }
  }
  }
`;
