import React from 'react';

export const LinkBoxMyEvents = ({ page, icon: Icon, title, setActivePage, executeScroll, onClick }) => {
    return (
        <div onClick={() => {

            if (executeScroll) executeScroll()

            if (setActivePage)
                setActivePage(page)
            if (onClick)
                onClick()
        }}
            className={`square-box rect-variant flex rounded shadow ${setActivePage ? "bg-white" : "bg-grey-lighter"}`}>
            <div className="inner p-5 flex flex-col justify-center items-center">
                <div className="text-violet text-center text-5xl mb-10">
                    <Icon />
                </div>

                <h3 className="text-sm text-blue font-semibold text-center">{title}</h3>
            </div>
        </div>
    );
};
