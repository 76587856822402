import React,{useState} from 'react';
import moment from "moment";
import { NavLink } from "react-router-dom";

export const NewsCard = ({ entity }) => {
    let date = moment.unix(entity.publishedAt.date);
    const [backgroundImage, setBackgroundImage] = useState('/assets/images/no-pict.png');

    useState(() => {
        setBackgroundImage(entity.backgroundImage.entity.thumbnail.medium.url);
    }, [entity]);

    return (
        <article className="flex pt-20 pb-20 b-b ">
            <NavLink to={`/news/${entity.id}`} className="flex flex-1 items-start" exact={true}>
                <div style={{ width: 98 }}>
                    <img className="object-cover object-contain"
                         src={backgroundImage}
                        alt={entity.title} />
                </div>

                <div className="flex-1 ms-20">
                    <time className="flex-inline text-xs text-green mb-10"
                        dateTime={date.format()}>{date.format('DD MMMM YYYY')}</time>
                    <h2 className="text-base text-blue font-semibold mb-10">{entity.title}</h2>
                </div>
            </NavLink>
        </article>
    );
};
