import React from 'react';

export const FieldError = ({name, hasErrors, errors}) => {
    return (
        <>
            {hasErrors && errors && errors[name] &&
                <div className="text-sm text-red p-5">{errors[name]}</div>
            }
        </>
    );
};
