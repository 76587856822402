import axios from 'axios';
import { REACT_APP_URL } from '../constants';
import { settingConfiguration } from '../storage';

const reactAppUrl = settingConfiguration.get(REACT_APP_URL);

export const OpenRoom = (roomId) => {
    console.log("[CHAT] Open Room")
    return axios.get(`${reactAppUrl}/api/rooms/${roomId}`)
}

export const FetchMessages = (url, offset) => {
    console.log("[CHAT] Fetch Messages")
    return axios.get(`${url}?offset=${offset}`)
}

export const AddUserToRoom = (roomId, userIds) => {
    console.log("[CHAT] Add User to Room")
    return axios.post(
        `${reactAppUrl}/api/rooms/${roomId}/users-add`,
        {
            user_ids: userIds
        }
    )
}

export const RemoveUserFromRoom = (roomId, userIds) => {
    console.log("[CHAT] Remove User from Room")
    return axios.post(
        `${reactAppUrl}/api/rooms/${roomId}/users-remove`,
        {
            user_ids: userIds
        }
    )
}

export const CreateRoom = (data) => {
    console.log("[CHAT] Create Room")
    return axios.post(
        `${reactAppUrl}/api/rooms`,
        data
    )
}

export const UpdateRoom = (roomId, data) => {
    console.log("[CHAT] Update Room")
    return axios.put(
        `${reactAppUrl}/api/rooms/${roomId}`,
        data
    )
}

export const DeleteRoom = (roomId) => {
    console.log("[CHAT] Delete Room")
    return axios.delete(`${reactAppUrl}/api/rooms/${roomId}`)
}

export const SendMessage = (roomId, data) => {
    console.log("[CHAT] Send Message")
    return axios.post(
        `${reactAppUrl}/api/rooms/${roomId}/messages`,
        data
    )
}

export const DeleteMessage = (roomId, messageId) => {
    console.log("[CHAT] Delete Message")
    return axios.delete(`${reactAppUrl}/api/rooms/${roomId}/messages/${messageId}`)
}

export const UpdateMessage = (roomId, messageId, data) => {
    console.log("[CHAT] Update Message")
    return axios.post(
        `${reactAppUrl}/api/rooms/${roomId}/messages/${messageId}`,
        data
    )
}

export const StartChatWith = (userId) => {
    console.log("[CHAT] Start Chat With")
    return axios.get(`${reactAppUrl}/api/chat/${userId}/start-chat-with`)
}

export const MarkAllMessagesAsRead = (roomId) => {
    console.log("[CHAT] Mark All Messages as Read")
    return axios.post(
        `${reactAppUrl}/api/rooms/${roomId}/mark-messages-as-read`,
    )
}

export const FetchRooms = (offset) => {
    console.log("[CHAT] Fetch Rooms")
    return axios.get(`${reactAppUrl}/api/rooms/all?offset=${offset}`)
}

export const FetchActiveUsers = () => {
    console.log("[CHAT] Get All Users")
    return axios.get(`${reactAppUrl}/api/chat/get-all-users`)
}

export const FetchUsers = () => {
    console.log("[CHAT] Get Users List")
    return axios .get(`${reactAppUrl}/api/chat/get-users`)
}

export const FetchGroupUsers = () => {
    console.log("[CHAT] Get Group Users List")
    return axios .get(`${reactAppUrl}/api/chat/get-users?all=1`)
}

export const FetchUserNotInRoom = (roomId) => {
    console.log("[CHAT] Get Users Not In Room")
    return axios.get(`${reactAppUrl}/api/rooms/${roomId}/users-not-in-room`)
}

export const RequestJoinGroup = (roomId) => {
    console.log('[CHAT] Request Join Group Chat')
    return axios.post(`${reactAppUrl}/api/rooms/${roomId}/join-requests`)
}

export const FetchJoinGroupRequest = (roomId) => {
    console.log('[CHAT] Get Join Group Chat Request')
    return axios.get(`${reactAppUrl}/api/rooms/${roomId}/join-requests/pending`)
}

export const AnswerJoinGroupRequest = (requestId, isAccepted) => {
    console.log('[CHAT] Answer Join Group Chat Request')
    return axios.post(`${reactAppUrl}/api/join-room-requests/${requestId}/review`, {
        approve: isAccepted
    })
}