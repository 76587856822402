import React from 'react';

export const InnerHeader = ({background, title}) => {
    let bgUrl = !!background ? background : require('@fci/business-layer/assets/background-header.png');

    return (
        <div className="flex flex-col flex-none justify-center bg-cover items-center bg-black w-full h-115 p-20"
             style={{ backgroundImage: `url(${bgUrl})` }}
        >
            <h2 className="font-bold text-3xl text-white text-center">{title}</h2>
        </div>
    );
};
