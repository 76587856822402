import React from 'react';

export const Button = ({
    className,
    onClick,
    children,
    variant,
    size,
    type,
    disabled = false
}) => {
    // Make this a switch if there are more colors
    let bgClass = (!!variant && variant === 'violet') ? 'bg-violet' : 'bg-blue';
    let sizeClass = 'h-56 rounded w-full';

    if (size === 'small') {
        sizeClass = 'h-22 rounded-lg leading text-xxs';
    }

    return (
        <div className={className}>
            <button
                type={!!type ? type : 'button'}
                className={`${sizeClass} b-none ${bgClass} uppercase text-white font-bold pl-20 pr-20`}
                onClick={onClick}
                disabled={disabled}
            >
                {children}
            </button>
        </div>
    );
};
