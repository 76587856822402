import React from 'react';

export const FeaturedImage = ({url}) => {
    if (!url) {
        return null;
    }

    return (
        <div className="bg-cover w-full h-176" style={{ backgroundImage: `url(${url})`}}/>
    );
};
