import React from 'react';

export const PublicLayout = (props) => {
    return (
        <div className="flex flex-1 items-center">
            <main className="w-full" style={{maxWidth: 400, margin: '0 auto'}}>
                {props.children}
            </main>
        </div>
    );
};
