import Axios from '../helpers/axios-helpers';
import { BASE_URL } from '../constants'
import { settingConfiguration } from '../storage'


export const GetAllNotification = async () => {
  const apiUrl = settingConfiguration.get(BASE_URL) ? settingConfiguration.get(BASE_URL) + 'en/api/notification/get-all' : '';
  return Axios.get(apiUrl);
}


export const ReadAllNotifications = async () => {
  const apiUrl = settingConfiguration.get(BASE_URL) ? settingConfiguration.get(BASE_URL) + 'en/api/notification/read-all' : '';
  return Axios.get(apiUrl);
}

export const DeleteNotification = async (id) => {
  const apiUrl = settingConfiguration.get(BASE_URL) ? settingConfiguration.get(BASE_URL) + `en/api/notification/${id}/delete` : '';
  return Axios.get(apiUrl);
}

export const DeleteAllNotification = async () => {
  const apiUrl = settingConfiguration.get(BASE_URL) ? settingConfiguration.get(BASE_URL) + 'en/api/notification/delete-all' : '';
  return Axios.get(apiUrl);
}

export const GetTotalUnread = async () => {
  const apiUrl = settingConfiguration.get(BASE_URL) ? settingConfiguration.get(BASE_URL) + 'en/api/notification/total-unread' : '';
  return Axios.get(apiUrl);
}

export const RegisterDevice = async (deviceId, browser, p256dh = '', expirationTime = '', auth = '') => {
  const apiUrl = settingConfiguration.get(BASE_URL) ? settingConfiguration.get(BASE_URL) + 'api/device/register' : '';
  const parameter = new URLSearchParams();
  parameter.append('device_id', deviceId);
  parameter.append('media', browser);
  if (browser !== "ios") {
    parameter.append('p256dh',p256dh);
    parameter.append('expirationTime',expirationTime);
    parameter.append('auth',auth);
  }
  return Axios.post(apiUrl, parameter)
}

export const UnRegisterDevice = async (media_id, deviceId = '') => {
  const apiUrl = settingConfiguration.get(BASE_URL) ? settingConfiguration.get(BASE_URL) + 'api/device/unregister' : '';
  const parameter = new URLSearchParams();
  parameter.append('media_id', media_id);
  parameter.append('device_id', deviceId);
  return Axios.post(apiUrl, parameter)
}