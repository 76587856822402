import React, { useState } from 'react';
import { Formik } from 'formik';
import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';
import { authenticate } from '@fci/business-layer/services/auth';
import storage from '@fci/business-layer/storage';
import { token, BASE_URL } from '@fci/business-layer/constants';
import { Button, FieldError, Input, Loader } from "../../components";
import { Helmet } from "react-helmet/es/Helmet";
import { settingConfiguration } from "@fci/business-layer/storage";
import { registerSubscription } from '../../serviceWorkerService';
import { UpdateAcceptCondition } from "@fci/business-layer/services/user";

const app_url = settingConfiguration.get(BASE_URL);
const LoginPage = props => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();
    const { setHasAcceptedTerms, hasAcceptedTerms } = props;

    const authorize = async (username, password) => {
        try {
            setFormError(false);
            setUsername(username);
            setPassword(password);

            await authenticate(username, password)
                .then(res => {
                    storage.setItem(token, res.data);
                    registerSubscription();
                    history.push('/');
                })
                .catch(err => {
                    if (err.response && err.response.data) {
                        setFormError(err.response.data.message);
                    }

                    setLoading(false);
                });
        } catch (err) {
            setLoading(false);
        }
    };

    const handleAcceptTerms = async (hasAcceptTerms) => {
        try {
            setFormError(false);

            await UpdateAcceptCondition(hasAcceptTerms)
                .then(res => {
                    setHasAcceptedTerms(true);
                    window.location.reload();
                })
                .catch(err => {
                    if (err.response && err.response.data) {
                        setFormError(err.response.data.message);
                    }

                    setLoading(false);
                });
        } catch (err) {
            setLoading(false);
        }
    }

    const renderLoginForm = () => {
        if (loading) {
            return <Loader />;
        }

        return <Formik
            initialValues={{ username: username, password: password }}
            validateOnChange={false}
            validateOnBlur={false}
            validate={values => {
                let errors = {};

                if (values.username === "") {
                    errors.username = t("requiredField");
                }

                if (values.password === "") {
                    errors.password = t("requiredField");
                }

                return errors;
            }}
            onSubmit={async (values) => {
                setLoading(true);
                authorize(values.username, values.password);
            }}>
            {({ handleChange, handleSubmit, values, touched, errors, isSubmitting }) => (
                <div>
                    <h3 className="text-sm text-center font-semibold text-violet tracking-xl uppercase mb-20">
                        {t("pleaseLogin")}</h3>

                    <Input name="username"
                        className="mb-15"
                        placeholder={t('username')}
                        value={values.username}
                        attributes={{ autoComplete: "new-password" }}
                        onChange={handleChange('username')}
                        errors={errors}
                        hasErrors={Object.keys(errors).length > 0} />

                    <Input name="password"
                        className="mb-15"
                        placeholder={t('password')}
                        type="password"
                        value={values.password}
                        attributes={{ autoComplete: "new-password" }}
                        onChange={handleChange('password')}
                        errors={errors}
                        hasErrors={Object.keys(errors).length > 0} />

                    {formError &&
                        <div className="text-sm text-red p-5 text-center">{formError}</div>
                    }

                    <Button
                        disabled={isSubmitting}
                        variant="violet"
                        onClick={handleSubmit}>
                        {t("login")}
                    </Button>
                </div>
            )}
        </Formik>
    };

    const renderTermsForm = () => {
        if (loading) {
            return <Loader />;
        }

        return <Formik
            initialValues={{ accept_terms: hasAcceptedTerms }}
            validateOnChange={false}
            validateOnBlur={false}
            validate={values => {
                let errors = {};

                if (!values.accept_terms) {
                    errors.accept_terms = t("requiredField");
                }

                return errors;
            }}
            onSubmit={async (values) => {
                setLoading(true);
                await handleAcceptTerms(values.accept_terms);
            }}>
            {({ handleChange, handleSubmit, values, touched, errors, isSubmitting }) => (
                <div>
                    <h3 className="text-sm text-center font-semibold text-violet tracking-xl uppercase mb-20">
                        {t("termsAndCondition")}</h3>

                    <p className="mb-20">{t("termAndConditionText")}</p>

                    <div className="tick-input checkbox mb-20">
                        <div className="tick-input-wrapper">
                            <input type="checkbox"
                                name="accept_terms"
                                id="accept_terms"
                                onChange={handleChange('accept_terms')} />

                            <label htmlFor="accept_terms" className="text-sm text-base-color">
                                {t('iAcceptThe')}
                                <a href={app_url + 'general-conditions'}
                                    className="underline text-base-color"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {t("termsAndCondition")}</a>
                            </label>
                        </div>

                        <FieldError name="accept_terms"
                            hasErrors={Object.keys(errors).length > 0}
                            errors={errors} />
                    </div>

                    {formError &&
                        <div className="text-sm text-red p-5 text-center">{formError}</div>
                    }

                    <Button
                        disabled={isSubmitting}
                        variant="violet"
                        onClick={handleSubmit}>
                        {t("continue")}
                    </Button>
                </div>
            )}
        </Formik>
    };

    return (
        <div className="p-15">
            <Helmet>
                <body className="grey-gradient" />
            </Helmet>

            <div className="flex flex-1 justify-center items-center mt-20 mb-40">
                <img src={require('@fci/business-layer/assets/fci-logo.svg')}
                    alt="Logo"
                    style={{ width: 176 }} />
            </div>

            <h2 className="text-center font-bold text-blue text-xl mb-20">
                <Trans i18nKey="welcomeAppTitle">
                    Welcome to the FCI App <br />dedicated to Our Members
                </Trans>
            </h2>

            <div className="rounded shadow bg-white mb-30 pt-30 pb-30 pl-15 pr-15">
                {hasAcceptedTerms
                    ? renderLoginForm()
                    : renderTermsForm()
                }
            </div>

            {hasAcceptedTerms &&
                <p className="text-center mb-20">
                    <a href={app_url + 'reset-password'}
                        className="text-sm font-semibold underline text-violet">
                        {t("forgotPassword")}</a>
                </p>
            }
        </div>
    );
};

export default LoginPage;
