import { RegisterDevice, UnRegisterDevice } from "@fci/business-layer/services";
import storage from "@fci/business-layer/storage";
import { token, media_id, device_id } from "@fci/business-layer/constants";

export const handleOnUpdate = (registration) => {
    // Make sure that any new version of a service worker will take over the page
    // and become activated immediately.
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
        window.addLog("waitingServiceWorker Ready")
        waitingServiceWorker.addEventListener("statechange", event => {
            window.addLog(`waitingServiceWorker ${event.target.state}`)
            if (event.target.state === "activated") {
                window.addLog(`waitingServiceWorker Reload`)
            }
        });
        window.addLog(`postMessage`)
        waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    } else {
        window.addLog("waitingServiceWorker Not Ready")
    }
};

const checkWithPreviousSubscription = (subscription) => {
    return JSON.stringify(subscription) === storage.getItem('device');
};


const getUser = () => {
    return storage.getItem(token);
};

const checkSubscription = async (subscription, registration) => {
    if (getUser()) {
        subscription = typeof subscription === "string" ? JSON.parse(subscription) : JSON.parse(JSON.stringify(subscription));
        const response = await RegisterDevice(subscription.endpoint, "browser", subscription?.keys?.p256dh, subscription?.expirationTime, subscription.keys.auth);
        if (response.data.media_id) {
            storage.setItem(media_id, response.data.media_id);
            storage.setItem(device_id, subscription.endpoint);
        }
    }
};

export const handleSubscription = async (subscription, registration) => {
    storage.setItem('device', JSON.stringify(subscription));
    await checkSubscription(subscription, registration);
};

export const registerSubscription = async () => {
    const data = storage.getItem('device');
    // console.log(`Info device : ${JSON.stringify(data)}`)
    if (data !== undefined) {
        await checkSubscription(data);
    }
};

export const unRegisterSubscription = async (callback) => {
    if (navigator.serviceWorker.controller) {
        const reg = await navigator.serviceWorker.ready;

        if (reg && window.PushManager && reg.pushManager) {
            const subscription = await reg.pushManager.getSubscription();

            if (!!subscription) {
                const successful = await subscription.unsubscribe();
                if (successful === true) {
                    await UnRegisterDevice(storage.getItem(media_id), storage.getItem(device_id));
                    storage.removeItem(media_id)
                }
            }
        }
    }

    if (callback) {
        callback();
    }
};
