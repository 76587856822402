import { gql } from 'apollo-boost';
import { BASE_URL } from '../constants';
import { settingConfiguration } from '../storage';
import Axios from '../helpers/axios-helpers';
export const CountryFragment = gql`
fragment CountryFragment on TaxonomyTermCitCountriesInformation {
  id: entityId
  code: fieldCitfIso2Code
  name: fieldCitfOfficialName
  label: entityLabel
}
`;
export const UserFragment = gql`
fragment UserFragment on User {
  id: uid
  name 
  fieldName {    
    title
    given
    middle
    family
    generational
    __typename
  }
  isChinese:fieldIsChineseName
  biography: fieldBiography {
    processed
  }
  userPicture {
    url
    derivative(style: LARGE) {
      height
      url
      width
    }
  }
  mail
  jobTitle: fieldJobTitle
  phone: fieldPhone
  linkedin: fieldLinkedin{
    uri
  }
  fieldCompanyName
  fieldCompanyNameCalculated  
  notification: fieldEmailNotification
  hasAcceptCondition: fieldHasAcceptCondition
  companyCountry: fieldCompanyCountry {
    entity {
      ...CountryFragment
    }
  }
  companyNameLinked:fieldCompanyNameCalculated
  countryLinked: fieldCompanyCountryCalculated {
    entity {
      ...CountryFragment
    }
  }
  timezone
}
${CountryFragment}
`;

export const GetCurrentUserDetail = gql`
query {
  user:currentUserContext {
    ...UserFragment
  }
}

${UserFragment}
`;
/*
  Title / prfix
  firstname / given
  western name / midle
  lastname / family
  suffix / generational

  TP #233309
*/
export function GetFullName(user) {
  if (user?.fieldName) {
    const { title, generational, middle, family, given } = user.fieldName;
    if (user?.isChinese && user.isChinese === true) {
      return `${title ?? ""} ${generational ?? ""} ${family ?? ""} ${given ?? ""} (${middle ?? '-'})`.replace(/\s{2,}/g, ' ');
    } else {
      return `${title ?? ""} ${given ?? ""} ${generational ?? ""} ${family ?? ""}`.replace(/\s{2,}/g, ' ');
    }
  }
  if (!user?.fieldName) return `${user?.name}`.replace(/\s{2,}/g, ' ');
  return undefined;
}

export function GetCompanyName(user) {
  if (user?.companyName)
    return user.companyName;
  return user?.companyNameLinked;
}

export function GetCompanyCountryCode(user) {
  if (user?.companyCountry?.entity?.code)
    return user.companyCountry?.entity?.code;
  return user?.countryLinked?.entity?.code;
}

export function GetCompanyCountry(user) {
  if (user?.companyCountry?.entity?.label)
    return user.companyCountry?.entity?.label;
  return user?.countryLinked?.entity?.label;
}

export function GetFullNameWithTitle(user) {
  if (!user?.fieldName) return user?.name

  const name = GetFullName(user);
  const title = user?.fieldName?.title;

  return (title ? title + " " : "") + name;
}

export async function UpdateEmailNotification(value) {
  const apiUrl = settingConfiguration.get(BASE_URL) ? settingConfiguration.get(BASE_URL) + 'api/profile/email-notification?_format=json' : '';
  await Axios.post(apiUrl, JSON.stringify({
    "value": value
  }));
}

export async function UpdateAcceptCondition(value) {
  const apiUrl = settingConfiguration.get(BASE_URL) ? settingConfiguration.get(BASE_URL) + 'api/profile/condition-acceptance?_format=json' : '';
  await Axios.post(apiUrl, JSON.stringify({
    "value": value
  }))
}

export function UpdateProfilePicture(fileName, content) { // content is string in base64 format
    const apiUrl = settingConfiguration.get(BASE_URL) ? settingConfiguration.get(BASE_URL) + 'api/profile/picture?_format=json' : '';
    return Axios.post(apiUrl, JSON.stringify({
        name: fileName,
        content: content
    }), {
        timeout: 300000
    })
}

export async function UserCheckLibraryPermission() {
  const apiUrl = settingConfiguration.get(BASE_URL) ? settingConfiguration.get(BASE_URL) + 'en/api/permissions' : '';
  return Axios.get(apiUrl, {
    params: {
      "_format" : "json",
      "permission": "access library"
    }
  })
}

export function UpdateProfile(userId, profile) {
  const apiUrl = settingConfiguration.get(BASE_URL) ? settingConfiguration.get(BASE_URL) + `user/${userId}?_format=json` : '';
  return Axios.patch(apiUrl, profile)
}