import React from 'react';
import { Trans } from 'react-i18next';
import { IconError } from '../Icons/Icons';


export const TimeoutError = (props) => {
    return <div className="flex items-center p-20">
        <IconError className='icon-lg' />
        <h2 className="text-lg font-semibold text-center">
            <Trans i18nKey="errorTimeout">
                Request has timeout please click refresh
            </Trans>
        </h2>
        <h2 className="text-lg font-semibold text-center text-blue-light pointer" onClick={() => {
            window.location.reload();
        }}>
            <Trans i18nKey="clickHereToTryAgain">
                Click here to try again
            </Trans>
        </h2>
    </div>

}