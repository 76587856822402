import Axios from '../helpers/axios-helpers';
import storage, { settingConfiguration } from '../storage'
import { token, BASE_URL, CLIENT_ID, CLIENT_SECRET } from '../constants'
let apiUrl;
let clientId = '';
let clientSecret = '';
export const initlizeEndpoint = () => {
  apiUrl = settingConfiguration.get(BASE_URL) ? settingConfiguration.get(BASE_URL) + 'oauth/token' : '';
  clientId = settingConfiguration.get(CLIENT_ID) ? settingConfiguration.get(CLIENT_ID) : '';
  clientSecret = settingConfiguration.get(CLIENT_SECRET) ? settingConfiguration.get(CLIENT_SECRET) : '';
  if (apiUrl === '')
    throw "API URL UNDEFINED"
  if (clientId === '')
    throw "CLIENT_ID UNDEFINED"
}
export function authenticate(username, password) {
  const parameter = new URLSearchParams();
  parameter.append('grant_type', 'password');
  parameter.append('client_id', clientId);
  parameter.append('client_secret', clientSecret);
  parameter.append('username', username);
  parameter.append('password', password);
  parameter.append('scope', 'authenticated administrator web_admin web_admin_secretariat marketing_committee executive_committee scf_committee legal_committee business_development_and_it_committee compliance_committee education_committee advisory_committee full_member associate_member affiliate_member secretariat sponsor chairman observer organization non_member');
  return Axios.post(apiUrl, parameter)
}

export async function refreshToken() {
  const parameter = new URLSearchParams();
  parameter.append('grant_type', 'refresh_token');
  parameter.append('client_id', clientId);
  parameter.append('client_secret', clientSecret);
  parameter.append('refresh_token', storage.getItem(token).refresh_token);
  const response = await Axios.post(apiUrl, parameter)
  storage.setItem(token, response.data)
}

let process = null;
export async function refreshTokenAsync() {
  if (process) {
    await process;
  } else {
    process = requestToken();
    await process;
  }
  process = null;
}

async function requestToken() {
  console.log('[REFRESH_TOKEN]')
  try {
    const parameter = new URLSearchParams();
    parameter.append('grant_type', 'refresh_token');
    parameter.append('client_id', clientId);
    parameter.append('client_secret', clientSecret);
    const accessToken = await storage.getItemAsync(token)
    const currentToken = accessToken.refresh_token
    parameter.append('refresh_token', currentToken);
    const config = { skipHeader: true, header: {
        'Authorization': "Bearer " + accessToken.access_token
    }};
    const response = await Axios.post(apiUrl, parameter, config)
    console.log(response.data)
    await storage.setItemNative(token, response.data)
  } catch (e) {
    console.log('ERROR =>', e)
  }
}