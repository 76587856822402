import React from 'react'
import { Switch } from 'react-router-dom';
import LoginPage from "./Pages/Auth/LoginPage";
import CustomRoute from "./components/Router/CustomRoute";

const HomePage = React.lazy(() => retry(() => import("./Pages/Home/HomePage")));
const NewsPage = React.lazy(() => retry(() => import("./Pages/News/NewsPage")));
const NewsDetailPage = React.lazy(() => retry(() => import("./Pages/News/NewsDetailPage")));
const EventsPage = React.lazy(() => retry(() => import("./Pages/Events/EventsPage")));
const EventsDetailPage = React.lazy(() => retry(() => import("./Pages/Events/EventsDetailPage")));
const EventsRegistrationPage = React.lazy(() => retry(() => import("./Pages/Events/EventsRegistrationPage")));
const MembersPage = React.lazy(() => retry(() => import("./Pages/Members/MembersPage")));
const MyEventsDetailPage = React.lazy(() => retry(() => import("./Pages/MyEvents/MyEventsDetailPage")));
const LibraryPage = React.lazy(() => retry(() => import("./Pages/Library/LibraryPage")));
const EducationDetailPage = React.lazy(() => retry(() => import("./Pages/Education/EducationDetailPage")));
const EducationPage = React.lazy(() => retry(() => import("./Pages/Education/EducationPage")));
const NotificationsPage = React.lazy(() => retry(() => import("./Pages/Notifications/NotificationsPage")));
const NotFoundPage = React.lazy(() => retry(() => import("./Pages/NotFound/NotFoundPage")));
const ProfilePage = React.lazy(() => retry(() => import("./Pages/Profile/ProfilePage")));
const PublicProfilePage = React.lazy(() => retry(() => import("./Pages/Profile/PublicProfilePage")));
const ShareProfilePage = React.lazy(() => retry(() => import("./Pages/Profile/ShareProfilePage")));
const ChatPage = React.lazy(() => retry(() => import("./Pages/Chat/ChatPage")));

function Routes() {
        return (
            <Switch>
                <CustomRoute exact={true} path={'/'} component={HomePage} isPrivate/>
                <CustomRoute exact={true} path='/news' component={NewsPage} isPrivate/>
                <CustomRoute exact={true} path='/news/:id' component={NewsDetailPage} isPrivate/>
                <CustomRoute exact={true} path='/events' component={EventsPage} isPrivate/>
                <CustomRoute exact={true} path='/events/:id' component={EventsDetailPage} isPrivate/>
                <CustomRoute exact={true} path='/events/:id/register' component={EventsRegistrationPage} isPrivate/>
                <CustomRoute exact={true} path='/members' component={MembersPage} isPrivate/>
                <CustomRoute exact={true} path='/my-events/:id' component={MyEventsDetailPage} isPrivate/>
                <CustomRoute exact={true} path='/library' component={LibraryPage} isPrivate/>
                <CustomRoute exact={true} path='/education' component={EducationPage} isPrivate/>
                <CustomRoute exact={true} path='/education/:id' component={EducationDetailPage} isPrivate/>
                <CustomRoute exact={true} path='/education/:id/:lang' component={EducationDetailPage} isPrivate/>
                <CustomRoute exact={true} path='/notifications' component={NotificationsPage} isPrivate/>
                <CustomRoute exact={true} path='/profile' component={ProfilePage} isPrivate/>
                <CustomRoute exact={true} path='/profile/share' component={ShareProfilePage} isPrivate/>
                <CustomRoute exact={true} path='/profiles' component={PublicProfilePage} isPrivate/>
                <CustomRoute exact={true} path='/chat' component={ChatPage} isPrivate/>
                <CustomRoute exact={true} path='/login' component={LoginPage}/>
                <CustomRoute component={NotFoundPage} isPrivate/>
            </Switch>
        );
}

export default Routes;

function retry(fn, retriesLeft = 5, interval = 1000) {
    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        // reject('maximum retries exceeded');
                        window.location.reload();

                        reject(error);
                        return;
                    }

                    // Passing on "reject" is the important part
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
            });
    });
}
